import React, { useState, useEffect, useContext, Fragment } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'

import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import Button from 'components/Button'
import { UserContext } from 'context/UserContext'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export default function ActiveLoanContract() {
    const navigate = useNavigate()
    const { state: authState } = useContext(AuthContext)
    const { state: userState } = useContext(UserContext)
    const {
        products: {
            personal_loan: { loan }
        }
    } = userState

    const [loading, setLoading] = useState(true)
    const [downloadingContract, setDownloadingContract] = useState(false)
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [fileUrl, setFileUrl] = useState('')

    useEffect(() => {
        setFileUrl(
            `${process.env.REACT_APP_API_KIWI_PAY}/loans/download-contract/${authState.accessTokenApp}?loan_id=${loan.id}`
        )
    }, [])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
        setLoading(false)
    }

    const onBackward = () => {
        navigate('/active-loan/loans-disbursed')
    }

    const onOpenContract = () => {
        setDownloadingContract(true)
        fetch(fileUrl).then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'Contract.pdf'
                alink.click()
                setDownloadingContract(false)
            })
        })
    }

    const nextPDFPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    const prevPDFPage = () => {
        if (pageNumber !== 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    return (
        <Fragment>
            <ChevronLeftIcon
                className="w-2 cursor-pointer mb-12"
                onClick={onBackward}
            />

            <div className="flex flex-col mb-10">
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Contrato de préstamo
                </h1>
                <div>
                    <Document
                        file={fileUrl}
                        scale="0.5"
                        width="300"
                        error="No se pudo cargar el contrato"
                        loading="Estamos cargando el contrato..."
                        onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>

                    {loading === false && (
                        <div className="flex justify-center mt-2 mx-auto text-center text-sm">
                            <div
                                className="w-20 cursor-pointer"
                                onClick={prevPDFPage}>
                                Atrás
                            </div>
                            <div className="mx-4">
                                {pageNumber} de {numPages}
                            </div>
                            <div
                                className="w-20 cursor-pointer"
                                onClick={nextPDFPage}>
                                Siguiente
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <Button
                className="w-full rounded-xl p-3 mt-auto bg-blue-kiwi text-white"
                loading={loading || downloadingContract}
                onClick={onOpenContract}>
                Descargar contrato
            </Button>
        </Fragment>
    )
}
