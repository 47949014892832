import React, { useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { UserContext } from 'context/UserContext'

import BackwardButton from 'components/Button/Backward'

export default function PersonalInformation() {
    const form = useRef()
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)

    const {
        phone_number,
        email,
        first_name,
        second_name,
        first_surname,
        second_surname,
        title,
        birthdate,
        identification_number,
        address,
        suite,
        state,
        city,
        zipcode
    } = userState.user

    const onBackward = () => {
        navigate('/settings')
    }

    /**
     * Se utiliza un default value, porque
     * los valores vienen como null de la BD...
     */
    const initialValues = {
        phone_number: phone_number ?? '',
        email: email ?? '',
        first_name: first_name ?? '',
        second_name: second_name ?? '',
        first_surname: first_surname ?? '',
        second_surname: second_surname ?? '',
        title: title ?? '',
        birthdate:
            birthdate.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3-$2-$1') ?? '',
        identification_number: identification_number ?? '',
        address: address ?? '',
        suite: suite ?? '',
        state: state ?? '',
        city: city ?? '',
        zipcode: zipcode ?? ''
    }

    return (
        <Formik innerRef={form} initialValues={initialValues}>
            {({ errors, touched }) => (
                <Form
                    className="w-full min-h-full flex flex-col px-6 py-10 grow"
                    autoComplete="off"
                    disabled>
                    <BackwardButton onBackward={onBackward} />

                    <h1 className="text-dark-kiwi font-semibold mb-10 text-2xl">
                        Información personal
                    </h1>

                    <div className="mb-10">
                        <div className="">
                            <label
                                htmlFor="phone_number"
                                className="inline-block text-sm mb-2">
                                Número telefónico
                            </label>
                            <Field
                                id="phone_number"
                                name="phone_number"
                                className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                    errors.phone_number && touched.phone_number
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : ''
                                }`}
                                disabled
                            />
                            <ErrorMessage
                                name="phone_number"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="mt-6">
                            <label
                                htmlFor="email"
                                className="inline-block text-sm mb-2">
                                Correo electrónico
                            </label>
                            <Field
                                id="email"
                                name="email"
                                className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                    errors.email && touched.email
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : ''
                                }`}
                                disabled
                            />
                            <ErrorMessage
                                name="email"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="mt-8 h-[1px] w-full bg-gray-300-kiwi"></div>

                        <div className="flex gap-5 mt-6">
                            <div className="w-1/2">
                                <label
                                    htmlFor="first_name"
                                    className="inline-block text-sm mb-2">
                                    Primer nombre
                                </label>
                                <Field
                                    id="first_name"
                                    name="first_name"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.first_name && touched.first_name
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="first_name"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                            <div className="w-1/2">
                                <label
                                    htmlFor="second_name"
                                    className="inline-block text-sm mb-2">
                                    Segundo nombre
                                </label>
                                <Field
                                    id="second_name"
                                    name="second_name"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.second_name &&
                                        touched.second_name
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="second_name"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <div className="flex gap-5 mt-6">
                            <div className="w-1/2">
                                <label
                                    htmlFor="first_surname"
                                    className="inline-block text-sm mb-2">
                                    Primer apellido
                                </label>
                                <Field
                                    id="first_surname"
                                    name="first_surname"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.first_surname &&
                                        touched.first_surname
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="first_surname"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                            <div className="w-1/2">
                                <label
                                    htmlFor="second_surname"
                                    className="inline-block text-sm mb-2">
                                    Segundo apellido
                                </label>
                                <Field
                                    id="second_surname"
                                    name="second_surname"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.second_surname &&
                                        touched.second_surname
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="second_surname"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>
                        <div className="flex gap-5 mt-6">
                            <div className="w-1/2">
                                <label
                                    htmlFor="title"
                                    className="inline-block text-sm mb-2">
                                    Título
                                </label>
                                <Field
                                    id="title"
                                    name="title"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.title && touched.title
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="title"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                            <div className="w-1/2">
                                <label
                                    htmlFor="birthdate"
                                    className="inline-block text-sm mb-2">
                                    Nacimiento
                                </label>
                                <Field
                                    id="birthdate"
                                    name="birthdate"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.birthdate && touched.birthdate
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="birthdate"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>

                        <div className="mt-6">
                            <label
                                htmlFor="identification_number"
                                className="inline-block text-sm mb-2">
                                Número de seguro social
                            </label>
                            <Field
                                id="identification_number"
                                name="identification_number"
                                className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                    errors.identification_number &&
                                    touched.identification_number
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : ''
                                }`}
                                disabled
                            />
                            <ErrorMessage
                                name="identification_number"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="mt-8 h-[1px] w-full bg-gray-300-kiwi"></div>

                        <div className="mt-6">
                            <label
                                htmlFor="address"
                                className="inline-block text-sm mb-2">
                                Dirección
                            </label>
                            <Field
                                id="address"
                                name="address"
                                className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                    errors.address && touched.address
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : ''
                                }`}
                                disabled
                            />
                            <ErrorMessage
                                name="address"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="mt-6">
                            <label
                                htmlFor="suite"
                                className="inline-block text-sm mb-2">
                                Apt/Suite
                            </label>
                            <Field
                                id="suite"
                                name="suite"
                                className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                    errors.suite && touched.suite
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : ''
                                }`}
                                disabled
                            />
                            <ErrorMessage
                                name="suite"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="mt-6">
                            <label
                                htmlFor="state"
                                className="inline-block text-sm mb-2">
                                Estado
                            </label>
                            <Field
                                id="state"
                                name="state"
                                className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                    errors.state && touched.state
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : ''
                                }`}
                                disabled
                            />
                            <ErrorMessage
                                name="state"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        <div className="flex gap-5 mt-6">
                            <div className="w-1/2">
                                <label
                                    htmlFor="city"
                                    className="inline-block text-sm mb-2">
                                    Ciudad
                                </label>
                                <Field
                                    id="city"
                                    name="city"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.city && touched.city
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="city"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                            <div className="w-1/2">
                                <label
                                    htmlFor="zipcode"
                                    className="inline-block text-sm mb-2">
                                    Código postal
                                </label>
                                <Field
                                    id="zipcode"
                                    name="zipcode"
                                    className={`placeholder:text-gray-400-kiwi bg-gray-100-kiwi w-full rounded-lg outline-none py-2 px-4 border border-solid border-gray-200-kiwi ${
                                        errors.zipcode && touched.zipcode
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : ''
                                    }`}
                                    disabled
                                />
                                <ErrorMessage
                                    name="zipcode"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
