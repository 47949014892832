import dayjs from 'dayjs'
import React, { useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import moneyFormat from 'utils/moneyFormat'

import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import moneyImage from 'assets/money_home.svg'
import File from 'assets/file.svg'
import ArrowRight from 'assets/arrowRight.svg'
import { UserContext } from 'context/UserContext'

export default function ActiveLoanLoansDisbursed() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const {
        products: {
            personal_loan: { loan }
        }
    } = userState

    const onBackward = () => {
        navigate('/active-loan/disbursement')
    }

    const onContract = () => {
        navigate('/active-loan/loans-disbursed/contract')
    }

    return (
        <Fragment>
            <ChevronLeftIcon
                className="w-2 cursor-pointer mb-12"
                onClick={onBackward}
            />

            <div className="text-sm relative mb-10 flex flex-col rounded-2xl items-center px-4 pt-20 pb-4 shadow-xl shadow-dark-kiwi/5">
                <img
                    className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    width="92"
                    src={moneyImage}
                />
                <div className="text-dark-kiwi font-semibold mb-1 text-6xl">
                    ${moneyFormat(Number(loan.totalLoanAmount).toFixed(2))}
                </div>
                <div className="text-gray-500-kiwi text-xs mb-10">
                    Monto de préstamo
                </div>

                <div className="text-center p-4 bg-gray-50-kiwi mb-6">
                    <p>
                        Vas a tener <b>{loan.term} pagos</b> mensuales de{' '}
                        <b>${loan.monthlyPayment?.toFixed(2)}</b> pagables el
                        día{' '}
                        {dayjs(loan.firstPaymentDate)
                            .add(1, 'day')
                            .format('DD')}{' '}
                        de cada mes
                    </p>
                </div>

                <div className="w-full flex flex-col gap-4">
                    <div className="flex justify-between gap-2">
                        <div>Monto total a repagar</div>
                        <b className="text-right">${loan.totalLoanAmount}</b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Pago total de interés</div>
                        <b className="text-right">
                            $
                            {(
                                loan.totalAmountIncludingFee -
                                loan.totalLoanAmount
                            ).toFixed(2)}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Primera fecha de pago</div>
                        <b className="text-right">
                            {dayjs(loan.firstPaymentDate)
                                .add(1, 'day')
                                .format('MMM DD, YYYY')}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Tasa de interés</div>
                        <b className="text-right">
                            {loan.interestRate?.toFixed(2)}%
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>APR</div>
                        <b className="text-right">{loan.apr?.toFixed(2)}%</b>
                    </div>
                </div>
            </div>

            <div
                className="flex items-center p-4 shadow-xl shadow-dark-kiwi/5 rounded-2xl cursor-pointer"
                onClick={onContract}>
                <img className="shrink-0 grow-0 w-8 mr-4" src={File} />
                <p className="w-full text-dark-kiwi font-semibold">
                    Visualizar contrato
                </p>
                <img
                    className="shrink-0 grow-0 w-4"
                    src={ArrowRight}
                    alt="icon-icon"
                />
            </div>
        </Fragment>
    )
}
