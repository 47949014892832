import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { useState, useEffect, useContext, Fragment } from 'react'
import dayjs from 'dayjs'
import STATE_CONSTANTS from 'constants/states'
import { STATES_AVAILABLES } from 'constants'
import dateDifference from 'utils/dateDifference'
import { UserContext } from 'context/UserContext'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import Button from 'components/Button'
import { useLocation } from 'react-router-dom'
import Advisement from 'components/Advisement'

export default function PaymentDay({ onSuccess }) {
    const { state: userState } = useContext(UserContext)
    const { state: approvedState } = useContext(ApprovedLoanContext)
    const location = useLocation()

    const [dayList, setDayList] = useState([])
    const [canSelectLastDayOfMonth, setCanSelectLastDayOfMonth] =
        useState(false)
    const { daysOptionsList, lastDayOption } = STATE_CONSTANTS(
        userState.user.state
    ).approved

    useEffect(() => {
        createDayList()
    }, [])

    const lastDayOfMonth = () => {
        let lastDayPresentMonth = dayjs().endOf('month')

        if (differenceLastDaytoToday(lastDayPresentMonth) < 15) {
            lastDayPresentMonth = lastDayPresentMonth.add(1, 'month')
            let finalLastDay = dayjs(lastDayPresentMonth).endOf('month')
            return finalLastDay.format('YYYY-MM-DD')
        }

        return lastDayPresentMonth.format('YYYY-MM-DD')
    }

    const differenceLastDaytoToday = (lastDay) => {
        return dateDifference(lastDay, dayjs())
    }

    const createDayList = () => {
        let todayDate = dayjs()
        const DATE_THRESHOLD_15 = 15
        const DATE_THRESHOLD_45 = 45
        const defaultPaymentDates =
            userState?.user?.uw_details?.default_payment_dates ?? []

        if (
            defaultPaymentDates &&
            defaultPaymentDates.length > 0 &&
            userState?.user?.state !== STATES_AVAILABLES.florida
        ) {
            setCanSelectLastDayOfMonth(false)
            let newDayList

            if (defaultPaymentDates.length === 1) {
                const singleDate = defaultPaymentDates[0]
                newDayList = [
                    singleDate - 1,
                    singleDate,
                    singleDate + 1,
                    singleDate + 2
                ]
            } else {
                newDayList = defaultPaymentDates
            }

            const formatListOfDays = newDayList.map((dayOption) => {
                let finalDate = todayDate.date(dayOption)
                const datePlusOneMonth = finalDate.add(1, 'month')
                const datePlusTwoMonths = finalDate.add(2, 'month')
                const diffWithOneMonth = dateDifference(
                    datePlusOneMonth,
                    todayDate
                )
                if (diffWithOneMonth < DATE_THRESHOLD_15) {
                    return {
                        date: datePlusTwoMonths.format('YYYY-MM-DD'),
                        text: datePlusTwoMonths.format('DD [de] MMMM')
                    }
                } else if (diffWithOneMonth > DATE_THRESHOLD_45) {
                    return {
                        date: finalDate.format('YYYY-MM-DD'),
                        text: finalDate.format('DD [de] MMMM')
                    }
                } else {
                    return {
                        date: datePlusOneMonth.format('YYYY-MM-DD'),
                        text: datePlusOneMonth.format('DD [de] MMMM')
                    }
                }
            })

            setDayList(formatListOfDays)
        } else {
            setCanSelectLastDayOfMonth(lastDayOption)
            setDayList(calculateDaysRange(daysOptionsList, todayDate))
        }
    }

    const calculateDaysRange = (listDaysToAdd, todayDate) => {
        let rangeDates = listDaysToAdd
            .map((dayToAdd) => {
                return {
                    date: todayDate.add(dayToAdd, 'day').format('YYYY-MM-DD'),
                    text: todayDate.add(dayToAdd, 'day').format('DD [de] MMMM')
                }
            })
            .filter((item) => item.date.slice(-2) < (lastDayOption ? 28 : 31))

        return rangeDates
    }

    const validationSchema = Yup.object().shape({
        value: Yup.string().required('Este campo es requerido')
    })

    return (
        <Formik
            initialValues={approvedState.paymentDay}
            validationSchema={validationSchema}
            onSubmit={onSuccess}>
            {({ errors, touched }) => (
                <Fragment>
                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                            Escoge el primer día de pago de tu préstamo
                        </h1>

                        <p className="mb-10">
                            Los pagos se procesarán en la fecha seleccionada.
                        </p>

                        <div className="mb-auto">
                            <label
                                htmlFor="value"
                                className="inline-block text-sm mb-2">
                                Selecciona día de pago
                            </label>
                            <Field
                                as="select"
                                name="value"
                                id="value"
                                className={`w-full rounded-lg py-2 px-4 border ${
                                    errors.value && touched.value
                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                }`}>
                                <option defaultValue hidden></option>
                                {dayList.map((item, index) => (
                                    <option key={index} value={item.date}>
                                        {item.text}
                                    </option>
                                ))}

                                {canSelectLastDayOfMonth ? (
                                    <option value={lastDayOfMonth()}>
                                        Último día del mes
                                    </option>
                                ) : (
                                    ''
                                )}
                            </Field>
                            <ErrorMessage
                                name="value"
                                component="small"
                                className="text-red-kiwi inline-block text-xs w-full mt-2"
                            />
                        </div>

                        {location.state?.showWarning && (
                            <Advisement icon="shield_check">
                                <div className="font-bold">
                                    Detalles de préstamo
                                </div>
                                Es necesario volver a calcular tu fecha de pago
                                y los detalles de tu préstamo para recalcular tu
                                contrato
                            </Advisement>
                        )}

                        <Button
                            className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    )
}
