import React, { useState, createContext, useContext } from 'react'
import { UserContext } from './UserContext'
import http from '../utils/http'
import {
    refreshFinicity,
    saveFinicityConnection,
    createLinkFinicity
} from 'utils/finicity'
import { exchangePlaidToken, createLinkToken } from 'utils/plaid'
import STORES_LIST from 'data/stores_list'
export const ApprovedLoanContext = createContext()

export const ApprovedLoanProvider = ({ children }) => {
    const {
        state: userState,
        userTracking,
        deleteUserTracking
    } = useContext(UserContext)

    const initialState = {
        amount: {
            value: 0,
            termMonths: 0,
            completed: false
        },
        paymentDay: {
            value: '',
            completed: false
        },
        repaymentTerms: {
            APR: 0,
            interestRate: 0,
            firstPaymentDate: '',
            originationFee: 0,
            tempLoanId: '',
            termMonths: 0,
            totalAmountIncludingFee: 0,
            completed: false,
            created_at: ''
        },
        paymentProfile: {
            id: '',
            last4: '',
            bankName: '',
            completed: false
        },
        disbursement: {
            value: '',
            completed: false
        },
        disbursementProfile: {
            id: '',
            last4: '',
            bankName: '',
            completed: false
        },
        repaymentOption: {
            value: '',
            completed: false
        },
        repaymentOptionReviewing: {
            completed: false
        },
        repaymentProfile: {
            id: '',
            last4: '',
            bankName: '',
            completed: false
        },
        stores: {
            completed: false
        },
        paperCheckPlaid: {
            completed: false
        },
        paperCheckReviewing: {
            completed: false
        },
        persona: {
            completed: false
        },
        veritec: {
            completed: false
        },
        contract: {
            completed: false
        }
    }

    const [state, setState] = useState(() => {
        const {
            personal_loan: { tracking }
        } = userState.products

        const state = tracking?.context

        if (state === undefined) {
            return initialState
        }

        return state
    })

    const saveState = (state) => {
        setState(state)
        userTracking({
            status: 'approved',
            context: state
        })
    }

    const resetState = () => {
        setState(initialState)
        deleteUserTracking({
            status: 'approved'
        })
    }

    const getStores = () => {
        return {
            data: {
                stores: STORES_LIST
            }
        }
    }

    const getBankAccount = async () => {
        const response = await http.get('/payment-profiles/account_primary')

        return response
    }

    const createLoan = async ({ user_id, amount, tempLoanId, type }) => {
        const response = await http.post('/loans', {
            user_id,
            amount,
            loanpro_tmp_loan_id: tempLoanId.toString(),
            type
        })

        return response
    }

    const getContractPreview = async ({ user_id, tempLoanId }) => {
        const response = await http.get('/loans/contract', {
            responseType: 'blob',
            params: {
                user_id,
                loanpro_tmp_loan_id: tempLoanId
            }
        })

        return response
    }

    const checkContractActive = async ({ tempLoanId }) => {
        const response = await http.get('/loans/contract/check-loan', {
            params: {
                loanpro_tmp_loan_id: tempLoanId
            }
        })

        return response
    }

    const providerValues = {
        /** State */
        initialState,
        state,
        setState,
        saveState,
        resetState,

        /** Handlers */
        getStores,
        getBankAccount,
        createLoan,
        checkContractActive,
        getContractPreview,
        refreshFinicity,
        saveFinicityConnection,
        createLinkFinicity,
        createLinkToken,
        exchangePlaidToken
    }

    return (
        <ApprovedLoanContext.Provider value={providerValues}>
            {children}
        </ApprovedLoanContext.Provider>
    )
}
