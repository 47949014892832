import React from 'react'
import BaseModal from '../BaseModal'

const ConsentTermsModal = ({ isVisible, closeModal }) => {
    return (
        <BaseModal
            title="Consentimiento de Verificación eCBSV"
            isVisible={isVisible}
            closeModal={closeModal}>
            <div className="flex flex-col gap-6">
                <p>
                    Autorizo a la Administración del Seguro Social (SSA) a
                    verificar y divulgar a Persona con el fin de esta
                    transacción si el nombre, el Número de Seguro Social (SSN) y
                    la fecha de nacimiento que he enviado coinciden con la
                    información de los registros de la SSA. Mi consentimiento es
                    para una validación única dentro de las próximas 24 horas (1
                    día).
                </p>
                <p>
                    Al hacer clic en el botón de la página anterior, usted
                    acepta que su firma electrónica tiene el mismo significado
                    legal, validez y efecto que su firma manuscrita. Está
                    firmando el consentimiento para que SSA divulgue su
                    Verificación de SSN a Kiwi Financial, Inc y acepta la
                    política de privacidad de Persona.
                </p>
            </div>
        </BaseModal>
    )
}

export default ConsentTermsModal
