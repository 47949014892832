import React from 'react'
import { Route } from 'react-router-dom'

import { CreditLimitProvider } from 'context/CreditLimitContext'

import BlackList from 'views/mobile/BlackList'
import Home from 'views/mobile/Home'
import Products from 'views/mobile/Products'
import RejectedLoan from 'views/mobile/RejectedLoan'
import ShareReferralCode from 'views/mobile/ShareReferralCode'
import Settings from 'views/mobile/Settings'
import PersonalInformation from 'views/mobile/PersonalInformation'
import DuplicatedBankAccount from 'views/mobile/DuplicatedBankAccount'
import LiveChat from 'views/mobile/LiveChat'
import LiveChatDisabled from 'views/mobile/LiveChat/Disabled'

const AuthRoutes = (
    <Route>
        <Route exact path="home" element={<Home />} />
        <Route exact path="products" element={<Products />} />
        <Route exact path="black-list" element={<BlackList />} />
        <Route exact path="referrals" element={<ShareReferralCode />} />
        <Route exact path="rejected-loan" element={<RejectedLoan />} />
        <Route
            exact
            path="settings"
            element={
                <CreditLimitProvider>
                    <Settings />
                </CreditLimitProvider>
            }
        />
        <Route
            exact
            path="personal-information"
            element={<PersonalInformation />}
        />
        <Route
            exact
            path="duplicated-bank-account"
            element={<DuplicatedBankAccount />}
        />
        <Route exact path="live-chat" element={<LiveChat />} />
    </Route>
)

export default AuthRoutes
