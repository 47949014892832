import React, { useContext, Fragment, useRef } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import ProgressBar from 'components/ProgressBar'
import RepaymentDetails from 'templates/RepaymentDetails'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import { REPAYMENT_OPTIONS } from 'constants'

export default function ApprovedLoanVerifyRepaymentDetails() {
    const navigate = useNavigate()
    const repaymentRef = useRef()

    const {
        state: approvedState,
        saveState: saveApprovedState,
        initialState: initialApprovedState
    } = useContext(ApprovedLoanContext)

    const onBackward = () => {
        const repayment = repaymentRef?.current

        if (repayment.showPaymentDay) {
            if (
                approvedState.repaymentOption.value ===
                REPAYMENT_OPTIONS.paperCheck
            ) {
                return navigate('/approved-loan/repayment-options')
            }

            navigate('/approved-loan/repayment-profile')
        }

        if (repayment.showRepaymentTerms) {
            repayment.setShowPaymentDay(true)
            repayment.setShowRepaymentTerms(false)
        }
    }

    const onPaymentDaySuccess = ({ value }) => {
        saveApprovedState({
            ...approvedState,
            paymentDay: {
                value,
                completed: true
            },
            repaymentTerms: initialApprovedState.repaymentTerms
        })
    }

    const onRepaymentTermsSuccess = () => {
        saveApprovedState({
            ...approvedState,
            repaymentTerms: {
                ...approvedState.repaymentTerms,
                completed: true
            }
        })

        navigate('/approved-loan/contract')
    }

    return (
        <Fragment>
            <ProgressBar progress="12/13" onBackward={onBackward} />
            <RepaymentDetails
                ref={repaymentRef}
                onPaymentDaySuccess={onPaymentDaySuccess}
                onRepaymentTermsSuccess={onRepaymentTermsSuccess}
            />
        </Fragment>
    )
}
