import React, { useState, createContext } from 'react'
import http from 'utils/http'
import dayjs from 'dayjs'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
    const initialState = {
        location: '',
        products: {
            credit_builder: {
                status: 'apply',
                loan: null,
                courses: {
                    active: 0,
                    paidout: 0,
                    total: 0
                },
                tracking: null
            },
            personal_loan: {
                status: 'apply',
                loan: null,
                requirements: [],
                application: null,
                tracking: null,
                dq: {
                    status: null
                }
            },
            credit_monitoring: {
                status: 'no_apply'
            }
        },
        user: {
            first_name: '',
            last_name: '',
            address: '',
            birthdate: '',
            city: '',
            state: '',
            zipcode: '',
            email: '',
            identification_number: ''
        }
    }

    const [state, setState] = useState(initialState)

    const resetState = () => {
        setState(initialState)
    }

    const registerWaitingList = async (email, stateSelected) => {
        const response = await http.post('/waiting_list', {
            email,
            state: stateSelected
        })

        return response
    }

    const getUser = async (id) => {
        const response = await http.get(`/users/${id}`)

        return response
    }

    const updateUser = async (body) => {
        const response = await http.post(`/users/${state.user.id}`, body)

        return response
    }

    const registerUser = async (user) => {
        const response = await http.post('/users', {
            ...user
        })

        return response
    }

    const getReferrals = async (id) => {
        const response = await http.get(`/referrals/my-referrals/${id}`)

        return response
    }

    /**
     * Actualizar user tracking en la base de datos y en el state
     * dentro del producto.tracking
     */
    const userTracking = async ({ status, context }) => {
        const response = await http.post('/users/tracking', {
            status,
            context
        })

        const {
            data: { success, data }
        } = response

        if (success) {
            const { products } = state
            const { credit_builder, personal_loan } = products

            const trackingIsCreditBuilder = ['credit'].includes(status)
            const trackingIsPersonalLoan = ['apply', 'approved'].includes(
                status
            )

            /**
             * Buscar que tipo de tracking se esta agregando...
             * despues lo asigna al objeto producto...
             */
            if (trackingIsCreditBuilder) {
                if (credit_builder.tracking) {
                    credit_builder.tracking.context = context
                } else {
                    credit_builder.tracking = data
                }
            }

            if (trackingIsPersonalLoan) {
                if (personal_loan.tracking) {
                    personal_loan.tracking.context = context
                } else {
                    personal_loan.tracking = data
                }
            }

            /**
             * Mantener actualizado el tracking en el state local...
             */
            setState((prev) => ({
                ...prev,
                products
            }))
        }

        return response
    }

    const deleteUserTracking = async ({ status }) => {
        const response = await http.delete('/users/tracking', {
            data: {
                status
            }
        })

        return response
    }

    const getSessionTokenInquiryId = async (id) => {
        const response = await http.get(`/persona/session_token/${id}`)

        return response
    }

    const userVerify = async (body) => {
        const response = await http.post('/users/verify', body)

        return response
    }

    const getDocuments = async (type) => {
        const response = await http.get('/files', {
            params: {
                type
            }
        })

        return response
    }

    const checkEligibilityVeritec = async (user_id) => {
        const response = await http.get(`/check-eligibility/${user_id}`)
        return response
    }

    const addTagInquiryId = async (inquiry) => {
        const response = await http.post('/persona/inquiry-id-tag', {
            id: inquiry.inquiry_id,
            tag: inquiry.tag
        })

        return response
    }

    const validateDQRules = async (id) => {
        const response = await http.get(`/applications/evalueate/${id}`)

        return response
    }

    const paymentProfiles = async () => {
        const response = await http.get('/payment-profiles')

        return response
    }

    const updatePaymentProfile = async ({ primary, secondary }) => {
        const response = await http.put('/payment-profiles/account', {
            primary,
            secondary
        })

        return response
    }

    const getProducts = async () => {
        const response = await http.get('/products')

        return response
    }

    const getCreditBuilder = async () => {
        const response = await http.get('/products/credit-builder')

        return response
    }

    const getPersonalLoan = async () => {
        const response = await http.get('/products/personal-loan')

        return response
    }

    const getPaymentProfiles = async () => {
        const response = await http.get('/payment-profiles')

        return response
    }

    const makePayment = async ({
        loanpro_loan_id,
        loanpro_user_id,
        method_type,
        method_id,
        amount
    }) => {
        const response = await http.post(`/loans/${loanpro_loan_id}/payments`, {
            loanpro_loan_id,
            loanpro_user_id,
            method_type,
            method_id,
            amount
        })

        return response
    }

    const addCreditCard = async ({
        cardName,
        cardNumber,
        principal,
        expiration
    }) => {
        const response = await http.post('/payment-profiles/card', {
            cardholder_name: cardName,
            card_number: cardNumber.replace(/\s/g, ''),
            expiration_month: expiration.split('/')[0],
            expiration_year: expiration.split('/')[1],
            is_primary: principal
        })

        return response
    }

    const getLoanTransactions = async (loanId) => {
        const response = await http.get(`/loan/${loanId}/transaction`)

        return response
    }

    const getLoanPayments = async (loanId) => {
        const response = await http.get(`/loan/${loanId}/payments`)

        return response
    }

    const showMovePaymentDate = (product) => {
        const {
            products: {
                [product]: {
                    loan: {
                        daysPastDue,
                        contracDate,
                        last_payment_changed,
                        amountDue,
                        lastPaymentDate
                    }
                }
            }
        } = state

        /** Validación 01: Si no hay atraso en el pago, mostrar... */
        const validPastDue = daysPastDue === 0

        /** Validación 02: Si la fecha del contrato es después de 2023-11-01, mostrar.... */
        const validContractDate = dayjs(contracDate.replace(/Z/g, '')).isAfter(
            dayjs('2023-11-01')
        )

        /**
         * Validación 03: Si last_payment_changed es null, mostrar...
         * Validación 04: Si el dia de hoy es mayor a la última nueva fecha de pago, mostrar...
         */
        const validLastPaymentChanged =
            last_payment_changed === null
                ? true
                : dayjs().isAfter(
                      dayjs(last_payment_changed.new_date).add(1, 'day')
                  )

        /**
         * Validación 05: Validar que amountdue sea igual a 0.00 para mostrar... Si es diferente,
         * quiere decir que la fecha de pago es el día de hoy... Es un fix para Loanpro.
         */
        const validAmountDue = amountDue === '0.00'

        /**
         * Validación 06: Si la fecha de hoy es mayor a la fecha del último pago más 7 días, mostrar...
         */
        const validLastPaymentDate =
            lastPaymentDate === null ||
            dayjs().isAfter(dayjs(lastPaymentDate).add(7, 'day'))

        const value =
            validPastDue &&
            validContractDate &&
            validLastPaymentChanged &&
            validAmountDue &&
            validLastPaymentDate

        return {
            value,
            validPastDue,
            validContractDate,
            validLastPaymentChanged,
            validAmountDue,
            validLastPaymentDate
        }
    }

    const addBankAccount = async ({
        accountType,
        accountNumber,
        routingNumber
    }) => {
        const response = await http.post('/payment-profiles/account', {
            account_type: accountType,
            account_number: accountNumber,
            routing_number: routingNumber
        })

        return response
    }

    const providerValues = {
        /** State */
        state,
        setState,
        resetState,

        /** Handlers */
        registerWaitingList,
        registerUser,
        updateUser,
        userTracking,
        deleteUserTracking,
        getSessionTokenInquiryId,
        userVerify,
        getDocuments,
        getUser,
        getReferrals,
        checkEligibilityVeritec,
        addTagInquiryId,
        validateDQRules,
        paymentProfiles,
        updatePaymentProfile,

        getProducts,
        getCreditBuilder,
        getPersonalLoan,

        getPaymentProfiles,
        makePayment,
        addCreditCard,

        getLoanTransactions,
        getLoanPayments,

        showMovePaymentDate,

        addBankAccount
    }

    return (
        <UserContext.Provider value={providerValues}>
            {children}
        </UserContext.Provider>
    )
}
