import React, { useEffect, useContext, useState, Fragment } from 'react'
import { UserContext } from 'context/UserContext'

import PersonaVerification from './Verification'
import PersonaWaiting from './Waiting'

export default function PersonaTemplate({
    template,
    inquiryTag,
    context,
    onBackward,
    onContinue
}) {
    const {
        state: userState,
        updateUser,
        addTagInquiryId
    } = useContext(UserContext)
    const { state: currentState, saveState: saveCurrentState } =
        useContext(context)

    const [showWaiting, setShowWaiting] = useState(false)
    const [showVerification, setShowVerification] = useState(false)

    useEffect(() => {
        const { inquiry_id, inquiry_status } = userState?.user
        if (inquiry_id && inquiry_status === 'approved') {
            onApproved()
        } else if ([null, '', 'retry'].includes(inquiry_status)) {
            setShowVerification(true)
        } else {
            /** Si es failed, declined, etc... */
            setShowWaiting(true)
        }
    }, [])

    const onVerification = async (inquiry_id) => {
        if (inquiry_id) {
            await updateUser({ inquiry_id })
            await addTagInquiryId({
                inquiry_id,
                tag: inquiryTag
            })
        }

        setShowVerification(false)
        setShowWaiting(true)
    }

    const onApproved = async () => {
        saveCurrentState({
            ...currentState,
            persona: {
                completed: true
            }
        })

        onContinue()
    }

    return (
        <Fragment>
            {showVerification && (
                <PersonaVerification
                    template={template}
                    onBackward={onBackward}
                    onVerification={onVerification}
                />
            )}

            {showWaiting && <PersonaWaiting onApproved={onApproved} />}
        </Fragment>
    )
}
