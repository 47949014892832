import React, { useEffect, useContext, useState, Fragment } from 'react'
import { UserContext } from 'context/UserContext'

import UserWarningSVG from 'assets/onboarding_v3/user_warning.svg'
import MagnifyingSVG from 'assets/onboarding_v3/magnifying.svg'
import supportIcon from 'assets/icons/support.svg'
import pusher from 'utils/pusher'
import sleep from 'utils/sleep'

export default function PersonaWaitingTemplate({ onApproved }) {
    const {
        state: userState,
        setState: setUserState,
        getUser
    } = useContext(UserContext)

    const [verifyingIdentity, setVerifyingIdentity] = useState(true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        verifyInquiryStatus()
    }, [])

    const verifyInquiryStatus = async () => {
        const { id } = userState.user
        const { inquiry_status } = await upToDateUser(id)
        const channel = pusher.subscribe(id)

        setLoading(false)

        if (inquiry_status === 'approved') {
            return checkInquiryStatus(inquiry_status)
        }

        if (['failed', 'declined'].includes(inquiry_status)) {
            return setVerifyingIdentity(false)
        }

        channel.bind('persona', ({ message: inquiry_status }) => {
            checkInquiryStatus(inquiry_status, true)
        })

        await sleep(10000)

        checkInquiryStatus(inquiry_status)
    }

    /**
     * Obtiene el usuario y actualiza el contexto de Usuario
     * para tener una copia actualizada del usuario...
     */
    const upToDateUser = async (id) => {
        const {
            data: { user }
        } = await getUser(id)

        await setUserState((prev) => ({
            ...prev,
            user
        }))

        return user
    }

    const checkInquiryStatus = async (status, channelMessage) => {
        if (channelMessage) {
            const {
                user: { id }
            } = userState

            await upToDateUser(id)
        }

        if (status === 'approved') {
            onApproved()
        } else {
            setVerifyingIdentity(false)
        }
    }

    return (
        <Fragment>
            {loading === false && (
                <Fragment>
                    {verifyingIdentity === true ? (
                        <div className="flex flex-col justify-center min-h-full text-center m-auto">
                            <img className="mx-auto mb-8" src={MagnifyingSVG} />
                            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                                Revisando tu identidad
                            </h1>
                            <p>
                                Estamos analizando tu identidad, recibirás
                                respuesta en unos segundos
                            </p>
                        </div>
                    ) : (
                        <Fragment>
                            <div className="flex flex-col justify-center items-center min-h-full text-center m-auto">
                                <img
                                    className="mx-auto mb-8"
                                    src={UserWarningSVG}
                                />
                                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                                    No pudimos verificar tu identidad
                                </h1>
                                <p>
                                    Lamentablemente, no pudimos verificar tu
                                    identidad. Por favor, póngase en contacto
                                    con nuestro equipo de soporte al correo{' '}
                                    <strong>support@kiwicredito.com</strong>
                                </p>
                            </div>
                            <a
                                href="tel:+17874221861"
                                className="flex justify-center items-center rounded-xl p-3 mt-10 bg-blue-kiwi text-white">
                                <img className="mr-2" src={supportIcon} />
                                ¿Necesitas ayuda?
                            </a>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}
