import http from 'utils/http'

/**
 * Se obtiene el user_id del token y se busca por el
 * value: disbursement_check, y se retorna el registro encontrado,
 * de lo contrario se retorna null.
 * { "value": "disbursement_check", "status": "pending" }
 * { "value": "disbursement_check", "status": "resolved" }
 */
export const getDisbursementCheck = async () => {
    const response = await http.get('/verifications/disbursement-check')

    return response
}

/**
 * Se crea un registro con el value disbursement_check,
 * el application_id y el status pending, no payload, no member_id.
 */
const postDisbursementCheck = async () => {
    const response = await http.post('/verifications/disbursement-check')

    return response
}

// Se obtiene el user_id del token y se busca por el value: repayment_check, y se retorna el registro encontrado, de lo contrario se retorna null
/* { "value": "repayment_check", "status": "pending" }
    { "value": "repayment_check", "status": "resolved" }
 */
const getRepaymentCheck = async () => {
    const response = await http.get('/verifications/repayment-check')

    return response
}
// Se crea un registro con el value repayment_check, el application_id y el status pending, no payload, no member_id. (editado)
const postRepaymentCheck = async (e) => {
    const response = await http.post('/verifications/repayment-check')

    return response
}

// Cancela la solicitud de revisión de metodo de repago
const cancelRepaymentCheck = async (id) => {
    const response = await http.put(
        `/verifications/cancel/repayment-check/${id}`
    )

    return response
}

export default {
    getDisbursementCheck,
    postDisbursementCheck,
    getRepaymentCheck,
    postRepaymentCheck,
    cancelRepaymentCheck
}
