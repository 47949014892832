import React, { useContext, Fragment } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

export const ActiveLoanMiddleware = () => {
    const { state: userState } = useContext(UserContext)
    const {
        products: { personal_loan }
    } = userState

    return personal_loan.status === 'current' ? (
        <Outlet />
    ) : (
        <Navigate to="/home" />
    )
}
