import React from 'react'
import { Route } from 'react-router-dom'

/**
 * Context Providers
 */
import { CreditBuilderProvider } from 'context/CreditBuilderContext'

/**
 * Middlewares
 */
import { BlackListMiddleware } from './middlewares/AuthMiddleware'
import { CreditBuilderActiveMiddleware } from './middlewares/CreditBuilderMiddleware'

/**
 * Routes
 */
import CreditBuilder from 'views/mobile/CreditBuilder'

import CreditBuilderCourses from 'views/mobile/CreditBuilder/Courses'
import CreditBuilderBuildScore from 'views/mobile/CreditBuilder/BuildScore'
import CreditBuilderBuyCourse from 'views/mobile/CreditBuilder/BuyCourse'
import CreditBuilderSelectedCourse from 'views/mobile/CreditBuilder/SelectedCourse'
import CreditBuilderAddress from 'views/mobile/CreditBuilder/Address'
import CreditBuilderSSN from 'views/mobile/CreditBuilder/SSN'
import CreditBuilderRepaymentTerms from 'views/mobile/CreditBuilder/RepaymentTerms'
import CreditBuilderPaymentProfile from 'views/mobile/CreditBuilder/PaymentProfile'
import CreditBuilderPersona from 'views/mobile/CreditBuilder/Persona'
import CreditBuilderContract from 'views/mobile/CreditBuilder/Contract'
import CreditBuilderSuccess from 'views/mobile/CreditBuilder/Success'
import CreditBuilderCourse from 'views/mobile/CreditBuilder/Course'
import CreditBuilderLesson from 'views/mobile/CreditBuilder/Lesson'

import CreditBuilderActiveLayout from 'views/mobile/CreditBuilder/Active'
import CreditBuilderDisbursement from 'views/mobile/CreditBuilder/Active/Disbursement'
import CreditBuilderHistoryPayment from 'views/mobile/CreditBuilder/Active/HistoryPayment'
import CreditBuilderPaymentCalendar from 'views/mobile/CreditBuilder/Active/PaymentCalendar'
import CreditBuilderPaymentMethod from 'views/mobile/CreditBuilder/Active/PaymentMethod'
import CreditBuilderPaymentMethodDetails from 'views/mobile/CreditBuilder/Active/PaymentMethodDetails'
import CreditBuilderPaymentMethodCard from 'views/mobile/CreditBuilder/Active/PaymentMethodCard'
import CreditBuilderPaymentMethodSuccess from 'views/mobile/CreditBuilder/Active/PaymentMethodSuccess'
import CreditBuilderPaymentMethodRejected from 'views/mobile/CreditBuilder/Active/PaymentMethodRejected'
import CreditBuilderMakePayment from 'views/mobile/CreditBuilder/Active/MakePayment'
import CreditBuilderMovePaymentDate from 'views/mobile/CreditBuilder/Active/MovePaymentDate'
import CreditBuilderPayFee from 'views/mobile/CreditBuilder/Active/PayFee'
import CreditBuilderErrorPaymment from 'views/mobile/CreditBuilder/Active/PaymentFailed'
import CreditBuilderSuccessPaymment from 'views/mobile/CreditBuilder/Active/PaymentSuccess'

const CreditBuilderRoutes = (
    <Route
        exact
        path="credit-builder"
        element={
            <CreditBuilderProvider>
                <CreditBuilder />
            </CreditBuilderProvider>
        }>
        <Route element={<BlackListMiddleware />}>
            <Route exact path="" element={<CreditBuilderCourses />} />

            <Route element={<CreditBuilderActiveMiddleware />}>
                <Route
                    exact
                    path="build-score"
                    element={<CreditBuilderBuildScore />}
                />
                <Route
                    exact
                    path="buy-course"
                    element={<CreditBuilderBuyCourse />}
                />
                <Route
                    exact
                    path="selected-course"
                    element={<CreditBuilderSelectedCourse />}
                />
                <Route
                    exact
                    path="address"
                    element={<CreditBuilderAddress />}
                />
                <Route exact path="ssn" element={<CreditBuilderSSN />} />
                <Route
                    exact
                    path="repayment-terms"
                    element={<CreditBuilderRepaymentTerms />}
                />
                <Route
                    exact
                    path="payment-profile"
                    element={<CreditBuilderPaymentProfile />}
                />
                <Route
                    exact
                    path="persona"
                    element={<CreditBuilderPersona />}
                />
                <Route
                    exact
                    path="contract"
                    element={<CreditBuilderContract />}
                />
            </Route>

            <Route exact path="success" element={<CreditBuilderSuccess />} />

            <Route exact path="active" element={<CreditBuilderActiveLayout />}>
                <Route exact path="" element={<CreditBuilderDisbursement />} />
                <Route
                    exact
                    path="payment-day"
                    element={<CreditBuilderMovePaymentDate />}
                />
                <Route exact path="pay-fee" element={<CreditBuilderPayFee />} />
                <Route
                    exact
                    path="make-payment"
                    element={<CreditBuilderMakePayment />}
                />
                <Route
                    exact
                    path="payment-success"
                    element={<CreditBuilderSuccessPaymment />}
                />
                <Route
                    exact
                    path="payment-failed"
                    element={<CreditBuilderErrorPaymment />}
                />
                <Route
                    exact
                    path="payment-method"
                    element={<CreditBuilderPaymentMethod />}
                />
                <Route
                    exact
                    path="payment-method/card"
                    element={<CreditBuilderPaymentMethodCard />}
                />
                <Route
                    exact
                    path="history-payment"
                    element={<CreditBuilderHistoryPayment />}
                />
                <Route
                    exact
                    path="payment-calendar"
                    element={<CreditBuilderPaymentCalendar />}
                />
                <Route
                    exact
                    path="payment-method/details"
                    element={<CreditBuilderPaymentMethodDetails />}
                />
                <Route
                    exact
                    path="payment-method/success"
                    element={<CreditBuilderPaymentMethodSuccess />}
                />
                <Route
                    exact
                    path="payment-method/rejected"
                    element={<CreditBuilderPaymentMethodRejected />}
                />
            </Route>
        </Route>

        <Route
            exact
            path="courses/:courseId"
            element={<CreditBuilderCourse />}
        />
        <Route
            exact
            path="courses/:courseId/:lessonId"
            element={<CreditBuilderLesson />}
        />
    </Route>
)

export default CreditBuilderRoutes
