import React from 'react'
import { Route } from 'react-router-dom'

/**
 * Middlewares
 */
import { ActiveLoanMiddleware } from './middlewares/ActiveLoanMiddleware'

/**
 * Routes
 */
import ActiveLoan from 'views/mobile/ActiveLoan'
import ActiveLoanDisbursement from 'views/mobile/ActiveLoan/Disbursement'
import ActiveLoanPayFee from 'views/mobile/ActiveLoan/PayFee'
import ActiveLoanHistoryPayment from 'views/mobile/ActiveLoan/HistoryPayment'
import ActiveLoanDisbursed from 'views/mobile/ActiveLoan/LoansDisbursed'
import ActiveLoanDisbursedContract from 'views/mobile/ActiveLoan/LoansDisbursedContract'
import ActiveLoanPaymentMethod from 'views/mobile/ActiveLoan/PaymentMethod'
import ActiveLoanPaymentMethodDetails from 'views/mobile/ActiveLoan/PaymentMethodDetails'
import ActiveLoanPaymentMethodCard from 'views/mobile/ActiveLoan/PaymentMethodCard'
import ActiveLoanPaymentMethodSuccess from 'views/mobile/ActiveLoan/PaymentMethodSuccess'
import ActiveLoanPaymentMethodRejected from 'views/mobile/ActiveLoan/PaymentMethodRejected'
import ActiveLoanMakePayment from 'views/mobile/ActiveLoan/MakePayment'
import ActiveLoanPaymentSuccess from 'views/mobile/ActiveLoan/PaymentSuccess'
import ActiveLoanPaymentFailed from 'views/mobile/ActiveLoan/PaymentFailed'
import ActiveLoanMovePaymentDate from 'views/mobile/ActiveLoan/MovePaymentDate'

const ActiveLoanRoutes = (
    <Route element={<ActiveLoanMiddleware />}>
        <Route exact path="active-loan" element={<ActiveLoan />}>
            <Route
                exact
                path="disbursement"
                element={<ActiveLoanDisbursement />}
            />
            <Route
                exact
                path="history-payment"
                element={<ActiveLoanHistoryPayment />}
            />
            <Route exact path="pay-fee" element={<ActiveLoanPayFee />} />
            <Route
                exact
                path="loans-disbursed"
                element={<ActiveLoanDisbursed />}
            />
            <Route
                exact
                path="loans-disbursed/contract"
                element={<ActiveLoanDisbursedContract />}
            />
            <Route
                exact
                path="payment-method"
                element={<ActiveLoanPaymentMethod />}
            />
            <Route
                exact
                path="payment-method/details"
                element={<ActiveLoanPaymentMethodDetails />}
            />
            <Route
                exact
                path="payment-method/card"
                element={<ActiveLoanPaymentMethodCard />}
            />
            <Route
                exact
                path="payment-method/success"
                element={<ActiveLoanPaymentMethodSuccess />}
            />
            <Route
                exact
                path="payment-method/rejected"
                element={<ActiveLoanPaymentMethodRejected />}
            />
            <Route
                exact
                path="make-payment"
                element={<ActiveLoanMakePayment />}
            />
            <Route
                exact
                path="payment-success"
                element={<ActiveLoanPaymentSuccess />}
            />
            <Route
                exact
                path="payment-failed"
                element={<ActiveLoanPaymentFailed />}
            />
            <Route
                exact
                path="payment-day"
                element={<ActiveLoanMovePaymentDate />}
            />
        </Route>
    </Route>
)

export default ActiveLoanRoutes
