import React, { useContext, useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'

import Button from 'components/Button'
import ResendCodeModal from 'components/Modals/ResendCode'
import phoneFormatter from 'phone-formatter'
import mixpanel from 'mixpanel-browser'
import Cookies from 'js-cookie'

export default function ChangePhoneVerify() {
    const navigate = useNavigate()

    const {
        verifyCodeChangePhone,
        sendCodeChangePhone,
        state: authState,
        setState: setAuthState
    } = useContext(AuthContext)

    const { new_cellphone } = authState
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [codeValues, setCodeValues] = useState(['', '', '', '', '', ''])

    useEffect(() => {
        if (new_cellphone === '') {
            navigate('/change-phone')
        }
    }, [])

    /**
     * Manejar el ingreso del código...
     */
    const onKeyDownCode = (event, codeIndex) => {
        let isDeleteKey =
            event.code === 'Backspace' ||
            event.key === 'Backspace' ||
            event.keyCode === 8
        let currentInput = event.target
        let code = codeValues[codeIndex]

        if (isDeleteKey) {
            /**  Al borrar número, recorrer el focus del input... */
            let previousInput = currentInput.previousSibling
            if (previousInput !== null && code === '') {
                previousInput.focus()
            }
        } else {
            /** Validación permitir solo un dígito... */
            if (currentInput.value.length > 0) {
                event.preventDefault()
            }

            /** Validar que el input sea numérico... */
            if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
            }
        }
    }

    const onChangeCode = (event, codeIndex) => {
        let newCodeValues = [...codeValues]
        let currentInput = event.target

        newCodeValues[codeIndex] = currentInput.value

        setCodeValues(newCodeValues)
        setError('')

        if (currentInput.value === '') {
            return
        }

        /** Al escribir un dígito brincar al siguiente input... */
        let nextInput = currentInput.nextSibling
        if (nextInput !== null) {
            nextInput.focus()
        } else {
            currentInput.blur()
        }
    }

    /**
     * Manejar el reenvio del código...
     */
    const [resendCount, setResendCount] = useState(30)
    const [resendCountStart, setResendCountStart] = useState(false)

    const onResendCode = async (type) => {
        setResendVisible(false)
        setResendCountStart(true)

        let timeLeft = resendCount
        let resendInterval = setInterval(() => {
            timeLeft = timeLeft - 1

            if (timeLeft <= 0) {
                clearInterval(resendInterval)
                setResendCount(30)
                setResendCountStart(false)
            } else {
                setResendCount(timeLeft)
            }
        }, 1000)

        try {
            const tokenNewPhone = Cookies.get('tokenNewPhone')
            if (type === 'phone') {
                setAuthState((prev) => ({
                    ...prev,
                    new_cellphone: '',
                    cellphone: ''
                }))
                navigate(`/change-phone/new-phone/${tokenNewPhone}`)
            } else if (type === 'sms') {
                sendCodeChangePhone({ cellphone: new_cellphone })
            } else if (type === 'whatsapp') {
                sendCodeChangePhone({
                    cellphone: new_cellphone,
                    service: 'whatsapp'
                })
            }
        } catch (error) {
            setError(
                'Parece que hubo un problema al reenviar tu código. Inténtalo de nuevo por favor'
            )
        }
    }

    /**
     * Enviar el código de verificación...
     */
    const onVerifyCode = async () => {
        let code = codeValues.join('')

        if (code === '') {
            return setError('Ingresa tu código de verificación')
        }

        if (code.length < 6) {
            return setError('Ingresa todos los dígitos de verificación')
        }

        try {
            setLoading(true)
            setAuthState((prev) => ({ ...prev, code }))

            const tokenNewPhone = Cookies.get('tokenNewPhone')

            const { data } = await verifyCodeChangePhone({
                cellphone: new_cellphone,
                code,
                tokenNewPhone
            })

            if (data.success) {
                setAuthState((prev) => ({ ...prev, new_cellphone: '' }))
                Cookies.set('access_token', data.data.access_token)
                navigate('/change-phone/success')
            }
        } catch (error) {
            const { messages } = error.response.data

            if (messages === 'Validation code error') {
                return setError(
                    `El código de validación es incorrecto. Inténtalo de nuevo por favor`
                )
            }

            if (messages.includes('no user record')) {
                mixpanel.track('Phone Verification Completed', {
                    $phone: phoneFormatter.format(
                        cellphone,
                        '+1 (NNN) NNN-NNNN'
                    )
                })

                return navigate('/login')
            }

            setError(
                'Parece que hubo un problema. Inténtalo de nuevo por favor'
            )
        } finally {
            setLoading(false)
        }
    }

    /**
     * Funcionalidad para abrir y cerrar el modal de reenvio del código...
     */
    const [resendVisible, setResendVisible] = useState(false)

    const onOpenResendModal = () => {
        if (resendCountStart === false) {
            setResendVisible(true)
        }
    }

    const onCloseResendModal = () => {
        setResendVisible(false)
    }

    return (
        <Fragment>
            <ResendCodeModal
                cellphone={new_cellphone}
                isVisible={resendVisible}
                closeModal={onCloseResendModal}
                resendCode={onResendCode}
            />

            <div className="flex flex-col grow pt-16">
                <div className="mb-10">
                    <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                        Código de verificación
                    </h1>
                    <p>
                        Ingresa el código de 6 dígitos que enviamos a tu número{' '}
                        {phoneFormatter.format(
                            new_cellphone,
                            '+1 (NNN) NNN-NNNN'
                        )}
                    </p>

                    <div className="flex gap-4 mt-10">
                        <input
                            className={`py-0 px-0 text-dark-kiwi font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
                                error
                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                            }`}
                            type="number"
                            inputMode="numeric"
                            onKeyDown={(e) => onKeyDownCode(e, 0)}
                            onChange={(e) => onChangeCode(e, 0)}
                        />
                        <input
                            className={`py-0 px-0 text-dark-kiwi font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
                                error
                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                            }`}
                            type="number"
                            inputMode="numeric"
                            onKeyDown={(e) => onKeyDownCode(e, 1)}
                            onChange={(e) => onChangeCode(e, 1)}
                        />
                        <input
                            className={`py-0 px-0 text-dark-kiwi font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
                                error
                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                            }`}
                            type="number"
                            inputMode="numeric"
                            onKeyDown={(e) => onKeyDownCode(e, 2)}
                            onChange={(e) => onChangeCode(e, 2)}
                        />
                        <input
                            className={`py-0 px-0 text-dark-kiwi font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
                                error
                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                            }`}
                            type="number"
                            inputMode="numeric"
                            onKeyDown={(e) => onKeyDownCode(e, 3)}
                            onChange={(e) => onChangeCode(e, 3)}
                        />
                        <input
                            className={`py-0 px-0 text-dark-kiwi font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
                                error
                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                            }`}
                            type="number"
                            inputMode="numeric"
                            onKeyDown={(e) => onKeyDownCode(e, 4)}
                            onChange={(e) => onChangeCode(e, 4)}
                        />
                        <input
                            className={`py-0 px-0 text-dark-kiwi font-semibold text-2xl text-center w-1/6 max-w-[56px] grow-0 h-16 rounded-lg ${
                                error
                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                    : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                            }`}
                            type="number"
                            inputMode="numeric"
                            onKeyDown={(e) => onKeyDownCode(e, 5)}
                            onChange={(e) => onChangeCode(e, 5)}
                        />
                    </div>

                    {error !== '' && (
                        <small className="text-red-kiwi inline-block text-xs w-full mt-2">
                            {error}
                        </small>
                    )}

                    <p className="mt-14 text-sm">
                        ¿No recibiste el código?{' '}
                        <span
                            className={`cursor-pointer text-blue-kiwi`}
                            onClick={onOpenResendModal}>
                            {resendCountStart === false
                                ? 'Reenviar'
                                : `Espera ${resendCount}s para volver a reenviar`}
                        </span>
                    </p>
                </div>

                <Button
                    className={`bg-blue-kiwi text-white rounded-xl p-3 mt-auto`}
                    loading={loading}
                    onClick={onVerifyCode}>
                    Continuar
                </Button>
            </div>
        </Fragment>
    )
}
