import React, { useEffect, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CreditBuilderContext } from 'context/CreditBuilderContext'

import Persona from 'templates/Persona'
import ProgressBar from 'components/ProgressBar'

export default function CreditBuilderPersona() {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    /**
     * Se necesita esperar a que el componente padre
     * este renderizado antes de renderizar los hijos...
     * Por una validacin en Persona...
     */
    useEffect(() => {
        setLoading(false)
    }, [])

    const onBackward = () => {
        navigate('/credit-builder/payment-profile')
    }

    const onContinue = () => {
        navigate('/credit-builder/contract')
    }

    return (
        <Fragment>
            <ProgressBar progress="7/8" onBackward={onBackward} />

            {loading === false && (
                <Persona
                    template="itmpl_J3L1kSS65gYtxCT5Meo914fY"
                    inquiryTag="credit_builder"
                    context={CreditBuilderContext}
                    onBackward={onBackward}
                    onContinue={onContinue}
                />
            )}
        </Fragment>
    )
}
