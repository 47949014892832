import dayjs from 'dayjs'
import React, { useState, useEffect, useContext, Fragment, useRef } from 'react'
import { UserContext } from 'context/UserContext'

import Button from 'components/Button'
import LoadingModal from 'components/Modals/Loading'
import moneyFormat from 'utils/moneyFormat'
import Advisement from 'components/Advisement'
import APRModal from 'components/Modals/APR'
import STATE_CONSTANTS from 'constants/states'
import ToggleInput from 'components/ToggleInput'
import { Calendar2Icon } from 'assets/icons_v3/fonts'
import { LoanAPI, RepaymentTermsAPI } from 'api'
import formatDate from 'utils/formatDate'
import Emitter from 'utils/emitter'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'

export default function RepaymentTerms({ onSuccess }) {
    const { state: userState } = useContext(UserContext)
    const { state: approvedState, saveState: saveApprovedState } =
        useContext(ApprovedLoanContext)

    const APRModalRef = useRef()

    const [loading, setLoading] = useState(false)
    const [schedulesPayments, setSchedulesPayments] = useState([])
    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        onCreateTempLoan()
    }, [])

    const onGetSchedulesPayments = async (loanpro_tmp_loan_id) => {
        const { data } = await LoanAPI.getScheduledPayments({
            loanpro_tmp_loan_id
        })

        setSchedulesPayments(data.data)
    }

    const onCreateTempLoan = async () => {
        try {
            setLoading(true)
            setShowLoader(true)

            let temploanId = approvedState.repaymentTerms.tempLoanId

            if (approvedState.repaymentTerms.tempLoanId === '') {
                const {
                    data: { loan: repaymentTerms }
                } = await RepaymentTermsAPI.createTempLoan({
                    user_id: userState.user.id,
                    amount: approvedState.amount.value,
                    termMonths: approvedState.amount.termMonths,
                    paymentDay: approvedState.paymentDay.value
                })

                saveApprovedState({
                    ...approvedState,
                    repaymentTerms: {
                        ...repaymentTerms,
                        completed: false
                    }
                })

                temploanId = repaymentTerms.tempLoanId
            }

            await onGetSchedulesPayments(temploanId)
        } catch (error) {
            Emitter.emit('onOpenNotification', {
                type: 'error',
                title: 'Error al crear tu préstamo',
                message:
                    'Parece que tuvimos problemas al crear tu préstamo. Por favor inténtalo nuevamente, si el problema persiste contacta a support@kiwicredito.com'
            })
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const onContinue = async () => {
        try {
            setLoading(true)

            await RepaymentTermsAPI.createLoanproCustomer(userState.user.id)

            onSuccess()
        } catch (error) {
            const { response } = error

            if (response.data?.error.includes('duplicate found')) {
                onSuccess()
            }
        } finally {
            setLoading(false)
        }
    }

    const formatPaymentDate = (date) => {
        const format = Number(date.replace(/^.*?(\d+).*/, '$1'))
        return dayjs.unix(format).add(1, 'day').format('MMMM DD, YYYY')
    }

    const paymentFrequency = STATE_CONSTANTS(userState.user.state).approved
        .paymentFrequency

    const transformDate = (date) => {
        const [month, day, year] = date.split('/')
        return formatDate(year + '/' + day + '/' + month, 'MMMM DD, YYYY')
    }

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />
            <APRModal ref={APRModalRef} />

            <div className="text-sm relative mb-10 flex flex-col items-center">
                <div className="text-gray-500-kiwi text-xs mb-1">
                    Monto de préstamo
                </div>

                <div className="text-dark-kiwi font-semibold mb-6 text-6xl">
                    $
                    {moneyFormat(Number(approvedState.amount.value).toFixed(2))}
                </div>

                <div className="w-full flex flex-col gap-4 mb-6">
                    <div className="flex justify-between gap-2">
                        <div>Pago {paymentFrequency}</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `$${approvedState.repaymentTerms.monthlyPayment?.toFixed(
                                      2
                                  )}`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Monto total a repagar</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `$${moneyFormat(
                                      approvedState.repaymentTerms
                                          .totalAmountIncludingFee
                                  )}`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Pago total de interés</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `$${moneyFormat(
                                      (
                                          approvedState.repaymentTerms
                                              .totalAmountIncludingFee -
                                          Number(approvedState.amount.value)
                                      )?.toFixed(2)
                                  )}`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Primera fecha de pago</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : formatPaymentDate(
                                      approvedState.repaymentTerms
                                          .firstPaymentDate
                                  )}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div>Tasa de interés</div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `${approvedState.repaymentTerms.interestRate?.toFixed(
                                      2
                                  )}%`}
                        </b>
                    </div>
                    <div className="flex justify-between gap-2">
                        <div
                            className="text-blue-kiwi underline cursor-pointer"
                            onClick={() => APRModalRef.current.openModal()}>
                            APR
                        </div>
                        <b className="text-right">
                            {showLoader
                                ? '...'
                                : `${approvedState.repaymentTerms.APR?.toFixed(
                                      2
                                  )}%`}
                        </b>
                    </div>
                </div>

                <ToggleInput
                    IconHeader={Calendar2Icon}
                    title={`Calendario de ${approvedState.repaymentTerms.termMonths} pagos`}
                    saveState={false}>
                    <div className="flex flex-col gap-4">
                        {schedulesPayments?.map((payment) => (
                            <div
                                className="flex items-center justify-between capitalize"
                                key={payment.date}>
                                <div>{transformDate(payment.date)}</div>
                                <div className="font-semibold">
                                    ${Number(payment.chargeAmount).toFixed(2)}
                                </div>
                            </div>
                        ))}
                    </div>
                </ToggleInput>
            </div>

            <Advisement icon="bulb_money" className="mt-auto">
                <strong>Puedes saldar tu préstamo antes</strong> sin costos
                extra, pagando solo los intereses acumulados hasta la fecha.
            </Advisement>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                loading={loading}
                onClick={onContinue}>
                Continuar
            </Button>
        </Fragment>
    )
}
