import mixpanel from 'utils/mixpanel'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { PERSONAL_LOAN_STATUS, CREDIT_BUILDER_STATUS } from 'constants'
import { UserContext } from 'context/UserContext'
import { ArrowRightIcon } from 'assets/icons_v3/fonts'

import MoneySVG from 'assets/icons_v3/money.svg'
import ChartLineSVG from 'assets/icons_v3/chart_line.svg'
import TachometerSVG from 'assets/icons_v3/tachometer.svg'
import { STATES_AVAILABLES } from 'constants'

import Menu from 'templates/Menu'
import {
    PersonalLoanApplyRedirect,
    PersonalLoanApprovedRedirect
} from 'utils/redirectPersonalLoan'

export default function Products() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const {
        user,
        products: { personal_loan, credit_builder }
    } = userState
    const {
        status: personalLoanStatus,
        tracking: personalLoanTracking,
        loan: personalLoanLoan
    } = personal_loan
    const { status: creditBuilderStatus } = credit_builder
    const personalLoanContext = personalLoanTracking?.context ?? {}

    /**
     * Personal Loan Status
     */
    const isPersonalLoanApply =
        personalLoanStatus === PERSONAL_LOAN_STATUS.apply &&
        !personalLoanContext.reviewing?.completed
    const isPersonalLoanReviewing =
        personalLoanStatus === PERSONAL_LOAN_STATUS.apply &&
        personalLoanContext.reviewing?.completed
    const isPersonalLoanApproved =
        personalLoanStatus === PERSONAL_LOAN_STATUS.approved
    const isPersonalLoanMoneyOnWay =
        personalLoanStatus === PERSONAL_LOAN_STATUS.approved &&
        personalLoanLoan &&
        personalLoanLoan.type === ''
    const isPersonalLoanMesalve =
        personalLoanStatus === PERSONAL_LOAN_STATUS.approved &&
        personalLoanLoan &&
        personalLoanLoan.type === 'mesalve'

    const isPersonalLoanRejected =
        personalLoanStatus === PERSONAL_LOAN_STATUS.rejected
    const isPersonalLoanActive =
        personalLoanStatus === PERSONAL_LOAN_STATUS.current

    /**
     * Credit Builder Status
     */
    const isCreditBuilderActive =
        creditBuilderStatus === CREDIT_BUILDER_STATUS.current

    const onPersonalLoanRedirect = async () => {
        if (isPersonalLoanMoneyOnWay) {
            return navigate('/moneys-on-the-way')
        }

        if (isPersonalLoanMesalve) {
            return navigate('/mesalve-code')
        }

        if (isPersonalLoanApply || isPersonalLoanReviewing) {
            const route = await PersonalLoanApplyRedirect(personalLoanContext)
            return navigate(route)
        }

        if (isPersonalLoanApproved) {
            const route = await PersonalLoanApprovedRedirect(
                personalLoanContext
            )
            return navigate(route)
        }

        if (isPersonalLoanRejected) {
            return navigate('/rejected-loan')
        }

        if (isPersonalLoanActive) {
            return navigate('/active-loan/disbursement')
        }
    }

    const onCreditBuilderRedirect = () => {
        mixpanel.track('Credit Builder')
        navigate('/credit-builder')
    }

    const onCreditMonitoringRedirect = async () => {
        mixpanel.track('Credit Monitoring')
        navigate('/credit-monitoring')
    }

    const PersonalLoanBadge = () => {
        const badgeText = (function () {
            if (isPersonalLoanActive) return 'Activo'
            if (isPersonalLoanReviewing) return 'En revisión'
            if (isPersonalLoanMoneyOnWay) return 'Dinero en camino'
            if (isPersonalLoanMesalve) return 'Obtener efectivo'
            if (isPersonalLoanApproved) return 'Aprobado'
            if (isPersonalLoanRejected) return 'No aprobado'
        })()

        if (isPersonalLoanApply) {
            return <ArrowRightIcon className="w-4 h-4 text-regal-blue-kiwi" />
        }

        return (
            <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-regal-blue-kiwi rounded-2xl text-xs text-white flex items-center gap-1">
                {badgeText} <ArrowRightIcon className="w-4 h-4 text-white" />
            </div>
        )
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 pt-10 pb-[136px]">
            <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                Explorar productos
            </h1>

            <div className="flex flex-col gap-6 mt-10">
                <div
                    className="p-4 rounded-2xl border border-slate-200 cursor-pointer"
                    onClick={onPersonalLoanRedirect}>
                    <div className="flex justify-between items-center mb-4">
                        <img src={MoneySVG} />
                        <PersonalLoanBadge />
                    </div>
                    <div className="font-semibold text-sm mb-2">
                        ¿Necesitas dinero?
                    </div>
                    <div className="text-xs text-gray-600-kiwi">
                        Puedes solicitar tu préstamo hasta de $1,000 ¡Ahora
                        mismo!
                    </div>
                </div>

                {user.state !== STATES_AVAILABLES.florida && (
                    <div
                        className="p-4 rounded-2xl border border-slate-200 cursor-pointer"
                        onClick={onCreditBuilderRedirect}>
                        <div className="flex justify-between items-center mb-4">
                            <img src={ChartLineSVG} />
                            {isCreditBuilderActive ? (
                                <div className="font-medium shrink-0 grow-0 px-3 py-1 bg-regal-blue-kiwi rounded-2xl text-xs text-white flex items-center gap-1">
                                    Activo{' '}
                                    <ArrowRightIcon className="w-4 h-4 text-white" />
                                </div>
                            ) : (
                                <ArrowRightIcon className="w-4 h-4 text-regal-blue-kiwi" />
                            )}
                        </div>
                        <div className="font-semibold text-sm mb-2">
                            Credit Builder
                        </div>
                        <div className="text-xs text-gray-600-kiwi">
                            Construye tu historial de crédito por tan solo
                            $5/mes
                        </div>
                    </div>
                )}
                <div
                    className="p-4 rounded-2xl border border-slate-200 cursor-pointer"
                    onClick={onCreditMonitoringRedirect}>
                    <div className="flex justify-between items-center mb-4">
                        <img src={TachometerSVG} />
                        <ArrowRightIcon className="w-4 h-4 text-regal-blue-kiwi" />
                    </div>
                    <div className="font-semibold text-sm mb-2">
                        Credit Monitoring
                    </div>
                    <div className="text-xs text-gray-600-kiwi">
                        Protégete contra el fraude y verifica tu reporte de
                        crédito
                    </div>
                </div>
            </div>

            <Menu selected="products" />
        </div>
    )
}
