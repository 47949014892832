import * as Yup from 'yup'
import valid from 'card-validator'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import React, { Fragment, useRef } from 'react'
import PatternFormat from 'react-number-format'

import MastercardLogoSVG from 'assets/logos/mastercard.svg'
import VisaLogoSVG from 'assets/logos/visa.svg'

import { BankCardIcon } from 'assets/icons_v3/fonts'
import Button from 'components/Button'

export default function AddCreditCardForm({ values, submitCreditCard }) {
    const form = useRef()

    const validationSchema = Yup.object().shape({
        brand: Yup.string().required('Este campo es requerido'),
        name: Yup.string()
            .required('Este campo es requerido')
            .matches(/^[aA-zZ\s]+$/, 'Ingrese un nombre válido'),
        number: Yup.string()
            .required('Este campo es requerido')
            .min(19, 'Escribe todo los dígitos de tu número de tarjeta.')
            .test(
                'test-number',
                'Número de tarjeta de crédito invalido',
                (value, context) => {
                    const { brand } = context.parent
                    const { isValid, card } = valid.number(value)

                    const isMastercardValid =
                        brand === 'mastercard' &&
                        card?.niceType === 'Mastercard' &&
                        isValid

                    const isVisaValid =
                        brand === 'visa' && card?.niceType === 'Visa' && isValid

                    if (isMastercardValid || isVisaValid) {
                        return true
                    }
                }
            ),
        expiration: Yup.string()
            .required('Este campo es requerido')
            .min(5, 'Escribe toda tu fecha de expiración')
            .test(
                'test-credit-card-expiration-date',
                'La fecha de expiración no debe ser en el pasado',
                (expirationDate) => {
                    if (!expirationDate) {
                        return false
                    }

                    const today = new Date()
                    const monthToday = today.getMonth() + 1
                    const yearToday = today.getFullYear().toString().substr(-2)

                    const [expMonth, expYear] = expirationDate.split('/')

                    if (Number(expYear) < Number(yearToday)) {
                        return false
                    } else if (
                        Number(expMonth) < monthToday &&
                        Number(expYear) <= Number(yearToday)
                    ) {
                        return false
                    }

                    return true
                }
            )
            .test(
                'test-credit-card-expiration-date',
                'Mes de expiración invalido',
                (expirationDate) => {
                    if (!expirationDate) {
                        return false
                    }

                    const [expMonth] = expirationDate.split('/')

                    if (Number(expMonth) > 12) {
                        return false
                    }

                    return true
                }
            ),
        cvc: Yup.string()
            .required('Este campo es requerido')
            .min(3, 'Escribe todos los dígitos de tu CVC'),
        principal: Yup.string().required('Este campo es requerido')
    })

    return (
        <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={submitCreditCard}
            innerRef={form}>
            {({ errors, touched }) => (
                <Fragment>
                    <Form className="flex flex-col grow" autoComplete="off">
                        <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                            Agrega tu perfil de pago
                        </h1>
                        <p className="mb-10">
                            Realiza pagos agregando tu tarjeta
                        </p>

                        <div className="flex flex-col gap-6 mb-auto">
                            <div>
                                <label
                                    htmlFor="brand"
                                    className="inline-block text-sm mb-2">
                                    Selecciona método de pago
                                </label>
                                <div className="flex gap-8">
                                    <div className="relative flex w-2/4">
                                        <Field
                                            className="peer hidden"
                                            type="radio"
                                            id="brandVisa"
                                            name="brand"
                                            value="visa"
                                        />
                                        <label
                                            className={`flex justify-center w-full rounded-lg outline-none px-4 py-2 border border-solid  ${
                                                errors.brand && touched.brand
                                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                    : 'peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 border-gray-200-kiwi'
                                            }`}
                                            htmlFor="brandVisa">
                                            <img
                                                className="w-16 flex justify-center"
                                                src={VisaLogoSVG}
                                                alt="kiwi-image"
                                            />
                                        </label>
                                    </div>

                                    <div className="relative flex w-2/4">
                                        <Field
                                            className="peer hidden"
                                            type="radio"
                                            id="brandMaster"
                                            name="brand"
                                            value="mastercard"
                                        />
                                        <label
                                            className={`flex justify-center w-full rounded-lg outline-none px-4 py-2 border border-solid  ${
                                                errors.brand && touched.brand
                                                    ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                    : 'peer-checked:border-blue-kiwi peer-checked:bg-blue-kiwi/10 border-gray-200-kiwi'
                                            }`}
                                            htmlFor="brandMaster">
                                            <img
                                                className="h-12"
                                                src={MastercardLogoSVG}
                                                alt="kiwi-image"
                                            />
                                        </label>
                                    </div>
                                </div>
                                <ErrorMessage
                                    name="brand"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="name"
                                    className="inline-block text-sm mb-2">
                                    Nombre
                                </label>
                                <Field
                                    placeholder="Nombre en la tarjeta"
                                    id="name"
                                    name="name"
                                    className={`w-full rounded-lg py-2 px-4 border ${
                                        errors.name && touched.name
                                            ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                            : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                    }`}
                                />
                                <ErrorMessage
                                    name="name"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="number"
                                    className="inline-block text-sm mb-2">
                                    Número de tarjeta
                                </label>
                                <div className="relative">
                                    <Field name="number">
                                        {({ field, ...props }) => (
                                            <PatternFormat
                                                {...field}
                                                {...props}
                                                className={`w-full rounded-lg py-2 px-4 border pr-14 ${
                                                    errors.number &&
                                                    touched.number
                                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                                format="#### #### #### ####"
                                                placeholder="0000 0000 0000 0000"
                                                mask=""
                                            />
                                        )}
                                    </Field>
                                    <BankCardIcon className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" />
                                </div>
                                <ErrorMessage
                                    name="number"
                                    component="small"
                                    className="text-red-kiwi inline-block text-xs w-full mt-2"
                                />
                            </div>

                            <div className="flex gap-4">
                                <div className="w-1/2">
                                    <label
                                        htmlFor="expiration"
                                        className="inline-block text-sm mb-2">
                                        Fecha de expiración
                                    </label>
                                    <Field name="expiration">
                                        {({ field, ...props }) => (
                                            <PatternFormat
                                                {...field}
                                                {...props}
                                                className={`w-full rounded-lg py-2 px-4 border ${
                                                    errors.expiration &&
                                                    touched.expiration
                                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                                format="##/##"
                                                placeholder="MM/AA"
                                                mask=""
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="expiration"
                                        component="small"
                                        className="text-red-kiwi inline-block text-xs w-full mt-2"
                                    />
                                </div>

                                <div className="w-1/2">
                                    <label
                                        htmlFor="cvc"
                                        className="inline-block text-sm mb-2">
                                        CVC
                                    </label>
                                    <Field name="cvc">
                                        {({ field, ...props }) => (
                                            <PatternFormat
                                                {...field}
                                                {...props}
                                                className={`w-full rounded-lg py-2 px-4 border ${
                                                    errors.cvc && touched.cvc
                                                        ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                                        : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                                                }`}
                                                format="###"
                                                placeholder="000"
                                                mask=""
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="cvc"
                                        component="small"
                                        className="text-red-kiwi inline-block text-xs w-full mt-2"
                                    />
                                </div>
                            </div>

                            <div className="flex justify-between">
                                <label
                                    htmlFor="principal"
                                    className="inline-block text-sm mb-2 mr-4">
                                    Guardar tarjeta como cuenta principal
                                </label>
                                <label
                                    htmlFor="principal"
                                    className="inline-flex relative items-center cursor-pointer">
                                    <Field
                                        type="checkbox"
                                        name="principal"
                                        id="principal"
                                        className="sr-only peer"
                                    />
                                    <div className="flex items-center w-12 h-7 rounded-full peer bg-gray-400-kiwi peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:left-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-500 peer-checked:bg-blue-kiwi"></div>
                                </label>
                            </div>
                        </div>

                        {form.current?.values.principal === true && (
                            <p className="text-xs text-gray-500-kiwi mt-10">
                                Esta cuenta será registrada como su método de
                                pago principal, si tu cuenta esta habilitado
                                para pagos automaticos se utilizara esta cuenta.
                            </p>
                        )}

                        <Button
                            className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                            type="submit">
                            Continuar
                        </Button>
                    </Form>
                </Fragment>
            )}
        </Formik>
    )
}
