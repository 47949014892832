import { ChevronLeftIcon } from 'assets/icons_v3/fonts'

const BackwardButton = ({ onBackward }) => {
    return (
        <ChevronLeftIcon
            className="cursor-pointer w-2 mb-12"
            onClick={onBackward}
        />
    )
}

export default BackwardButton
