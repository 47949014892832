import http from 'utils/http'

export const getProfiles = async () => {
    const response = await http.get('/payment-profiles')

    return response
}

const addCreditCard = async ({
    ip,
    loanpro_loan_id,
    card_number,
    expiration_year,
    expiration_month,
    cardholder_name,
    is_primary
}) => {
    const response = await http.post('/payment-profiles/card', {
        ip,
        loanpro_loan_id,
        card_number,
        cardholder_name,
        expiration_month,
        expiration_year,
        is_primary
    })

    return response
}

export default {
    getProfiles,
    addCreditCard
}
