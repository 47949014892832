import Emitter from 'utils/emitter'

import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import { ProductsAPI, LoanAPI, CreditBuilderAPI } from 'api'
import { CheckIcon, RightIcon } from 'assets/icons_v3/fonts'
import { CreditBuilderContext } from 'context/CreditBuilderContext'

import ProgressBar from 'components/ProgressBar'
import LoadingModal from 'components/Modals/Loading'
import Button from 'components/Button'
import ContractTemplate from 'templates/Contract'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'

export default function CreditBuilderContract() {
    const navigate = useNavigate()

    const {
        state: creditState,
        saveState: saveCreditState,
        resetState: resetCreditState
    } = useContext(CreditBuilderContext)

    const { state: userState, setState: setUserState } = useContext(UserContext)

    const { getData: getDataFingerprint } = useVisitorData(
        { extendedResult: true },
        { immediate: true }
    )

    const {
        user: { id: user_id }
    } = userState

    const { course, repaymentTerms, paymentDay } = creditState
    const { tempLoanId } = repaymentTerms

    const [contracts, setContracts] = useState([
        {
            title: 'Contrato de crédito',
            type: 'creditPayments',
            method: 'getContractPaymentsCreditBuilder',
            signed: false
        },
        {
            title: 'Autorización pagos ACH',
            type: 'achAuthorization',
            method: 'getContractAchAuthorizationCreditBuilder',
            signed: false
        }
    ])

    const [selectedContract, setSelectedContract] = useState({})
    const [showContract, setShowContract] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileUrl, setFileUrl] = useState('')
    const [acceptTerms, setAcceptTerms] = useState(false)
    const [contractReload, setContractReload] = useState(false)

    useEffect(() => {
        if (tempLoanId === '') {
            return navigate('/credit-builder/repayment-terms')
        }

        if (creditState.persona.completed === false) {
            return navigate('/credit-builder/persona')
        }
    }, [])

    useEffect(() => {
        const contractsSigned = contracts.every((contract) => contract.signed)
        if (contractsSigned) setAcceptTerms(true)
    }, [contracts])

    const getIPfingerprintJs = async () => {
        const dataFPJS = await getDataFingerprint({
            ignoreCache: true
        })

        return dataFPJS?.ip
    }

    const onBackward = () => {
        if (showContract) {
            return resetContractView()
        }

        navigate('/credit-builder/payment-profile')
    }

    const resetContractView = () => {
        setShowContract(false)
        setSelectedContract({})
        setFileUrl('')
    }

    const onSignIndividualContract = async () => {
        const newContracts = [...contracts]
        const contractIndex = newContracts.findIndex(
            (contract) => contract.type === selectedContract.type
        )

        newContracts[contractIndex] = {
            ...selectedContract,
            signed: true
        }

        setContracts(newContracts)
        resetContractView()
    }

    const onSignAllContract = async () => {
        try {
            setLoading(true)
            setShowLoader(true)

            const ip = await getIPfingerprintJs()
            await CreditBuilderAPI.signContract({
                user_id,
                loanpro_tmp_loan_id: tempLoanId.toString(),
                product_id: course.id,
                amount: course.price.toString(),
                ip
            })

            await resetCreditState()

            const {
                data: { data: credit_builder }
            } = await ProductsAPI.getCreditBuilder()

            setUserState({
                ...userState,
                products: {
                    ...userState.products,
                    credit_builder
                }
            })

            navigate('/credit-builder/success')
        } catch (error) {
            const { data } = error.response

            if (data.messages === 'ACTIVE_LOAN_ALREADY_EXISTS') {
                return Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Tienes un contrato activo',
                    message: `Parece que actualmente tienes un contrato activo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`
                })
            }

            if (data.messages === 'LOAN_LESS_7_DAYS') {
                return Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Días de espera...',
                    message: `Deben de pasar 7 días para volver a solicitar otro préstamo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`
                })
            }

            Emitter.emit('onOpenNotification', {
                type: 'error',
                title: 'Error al firmar tu contrato',
                message: `Parece que hubo un error, póngase en contacto con nuestro equipo de soporte al correo support@kiwicredito.com`
            })
        } finally {
            setLoading(false)
            setShowLoader(false)
        }
    }

    const onSelectContract = async (contract) => {
        if (contract.signed || loading) {
            return
        }

        try {
            setShowLoader(true)

            const ip = await getIPfingerprintJs()
            const { data } = await LoanAPI[contract.method]({
                user_id,
                loanpro_tmp_loan_id: tempLoanId,
                ip
            })

            if (data) {
                const reader = new window.FileReader()
                reader.readAsDataURL(data)
                reader.onload = () => {
                    setFileUrl(reader.result)
                    setSelectedContract(contract)
                    setShowContract(true)
                }
            }
        } catch (error) {
            if (!contractReload) onRecreateTempLoan()
            console.error(error)
        } finally {
            setShowLoader(false)
        }
    }

    const onRecreateTempLoan = async () => {
        try {
            const {
                data: {
                    data: { isActive }
                }
            } = await LoanAPI.checkContractActive({ tempLoanId })

            if (!isActive) {
                const { data } = await LoanAPI.createTempLoanCreditBuilder({
                    user_id,
                    price_id: course.price_id,
                    preferred_payment_date: paymentDay.value,
                    product_id: course.id
                })

                setContractReload(true)

                saveCreditState({
                    ...creditState,
                    repaymentTerms: {
                        ...creditState.repaymentTerms,
                        ...data.loan
                    }
                })
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <Fragment>
            <ProgressBar progress="7/7" onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />

            {showContract ? (
                <ContractTemplate
                    title={selectedContract.title}
                    fileUrl={fileUrl}
                    signContract={onSignIndividualContract}
                />
            ) : (
                <Fragment>
                    <div className="mb-10">
                        <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                            Contratos de préstamo
                        </h1>
                        <p>Conoce los documentos de tu préstamo:</p>
                    </div>

                    <div className="mb-10 flex flex-col gap-6">
                        {contracts.map((contract) => (
                            <div
                                className="flex cursor-pointer rounded-lg border border-solid border-gray-200-kiwi p-4"
                                onClick={() => onSelectContract(contract)}
                                key={contract.type}>
                                <div
                                    className={`flex shrink-0 w-6 h-6 rounded-lg mr-4 ${
                                        contract.signed
                                            ? 'bg-blue-kiwi'
                                            : 'border border-solid border-gray-200-kiwi'
                                    }`}>
                                    {contract.signed && (
                                        <CheckIcon className="text-white w-4 m-auto" />
                                    )}
                                </div>
                                <div className="mr-auto">{contract.title}</div>
                                {!contract.signed && (
                                    <RightIcon className="text-slate-300-kiwi" />
                                )}
                            </div>
                        ))}
                    </div>

                    <Button
                        className={`rounded-xl p-3 mt-auto ${
                            acceptTerms
                                ? 'bg-blue-kiwi text-white'
                                : 'bg-gray-200-kiwi text-gray-500-kiwi'
                        }`}
                        loading={loading}
                        disabled={acceptTerms === false}
                        onClick={onSignAllContract}>
                        Firmar contrato
                    </Button>
                </Fragment>
            )}
        </Fragment>
    )
}
