import React, { Fragment } from 'react'

import SuccessCardSVG from 'assets/onboarding_v3/success_card.svg'

export default function AddCreditCardSuccess({ accept }) {
    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img
                    className="mx-auto mb-8"
                    width="218"
                    src={SuccessCardSVG}
                />
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    Tu nuevo método de pago se agregó exitosamente
                </h1>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={accept}>
                Continuar
            </button>
        </Fragment>
    )
}
