import React, {
    useState,
    Fragment,
    useImperativeHandle,
    forwardRef,
    useContext
} from 'react'

import CreditCardForm from './Form'
import AddCreditCardSuccess from './success'
import AddCreditCardRejected from './rejected'

import { PaymentAPI } from 'api'
import { UserContext } from 'context/UserContext'
import LoadingModal from 'components/Modals/Loading'
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'

export default forwardRef(({ onBackward, currentFlow }, ref) => {
    const [creditCard, setCreditCard] = useState({
        brand: '',
        name: '',
        number: '',
        expiration: '',
        cvc: '',
        principal: false
    })
    const [showForm, setShowForm] = useState(true)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showRejected, setShowRejected] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const { state: userState } = useContext(UserContext)
    const {
        products: { personal_loan, credit_builder }
    } = userState

    const { getData: getDataFingerprint } = useVisitorData(
        { extendedResult: true },
        { immediate: true }
    )

    const LOANPRO_IDS = {
        personalLoan: personal_loan.loan?.id,
        creditBuilder: credit_builder.loan?.id
    }

    useImperativeHandle(ref, () => ({
        showForm,
        setShowForm,
        showSuccess,
        setShowSuccess,
        showRejected,
        setShowRejected
    }))

    const getIPfingerprintJs = async () => {
        const dataFPJS = await getDataFingerprint({
            ignoreCache: true
        })

        return dataFPJS?.ip
    }

    const onSubmitCreditCard = async (creditCard) => {
        try {
            setCreditCard(creditCard)
            setShowLoader(true)

            const { number, expiration, name, principal } = creditCard
            const [month, year] = expiration.split('/')
            const ip = await getIPfingerprintJs()

            await PaymentAPI.addCreditCard({
                ip,
                loanpro_loan_id: LOANPRO_IDS[currentFlow],
                card_number: number.replace(/\s/g, ''),
                expiration_year: year,
                expiration_month: month,
                cardholder_name: name,
                is_primary: principal
            })

            setShowSuccess(true)
        } catch (error) {
            console.error(error)
            setShowRejected(true)
        } finally {
            setShowForm(false)
            setShowLoader(false)
        }
    }

    const onAcceptSuccess = () => {
        onBackward()
    }

    const onAcceptRejected = () => {
        setShowForm(true)
        setShowRejected(false)
    }

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />

            {showForm && (
                <CreditCardForm
                    values={creditCard}
                    submitCreditCard={onSubmitCreditCard}
                />
            )}

            {showSuccess && <AddCreditCardSuccess accept={onAcceptSuccess} />}

            {showRejected && (
                <AddCreditCardRejected accept={onAcceptRejected} />
            )}
        </Fragment>
    )
})
