import React, { Fragment, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import AddCreditCardTemplate from 'templates/AddCreditCard'
import BackwardButton from 'components/Button/Backward'

export default function CreditBuilderActivePaymentMethodCard() {
    const creditCardRef = useRef()
    const navigate = useNavigate()

    const onBackward = () => {
        const creditCard = creditCardRef.current

        if (creditCard.showForm || creditCard.showSuccess) {
            navigate(-1)
        }

        if (creditCard.showRejected) {
            creditCard.setShowForm(true)
            creditCard.setShowRejected(false)
        }
    }

    return (
        <Fragment>
            <BackwardButton onBackward={onBackward} />

            <AddCreditCardTemplate
                currentFlow="creditBuilder"
                onBackward={onBackward}
                ref={creditCardRef}
            />
        </Fragment>
    )
}
