import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'
import DisbursmentTemplate from 'templates/Disbursment'
import {
    Calendar2Icon,
    CurrencyDollarIcon,
    Eye2Icon,
    ToDoIcon
} from 'assets/icons_v3/fonts'
import { CreditBuilderAPI } from 'api'
import { CREDIT_BUILDER_COURSE_STATUS } from 'constants'
import mixpanel from 'utils/mixpanel'
import PaidoutCourseComponent from 'components/PaidoutCourse'
import PaidoutCourseSkeleton from 'components/Skeleton/PaidoutCourse'

dayjs.extend(utc)

export default function CreditBuilderActiveDisbursement() {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const [paidoutCourses, setPaidoutCourses] = useState([])
    const [loading, setLoading] = useState([])

    const {
        user,
        products: {
            credit_builder: { loan, order_active }
        }
    } = userState

    useEffect(() => {
        getUserCourses()
    }, [])

    const getUserCourses = async () => {
        try {
            setLoading(true)

            const { data } = await CreditBuilderAPI.getUserCourses(user.id)

            setPaidoutCourses(
                data.filter(
                    (course) =>
                        course.status === CREDIT_BUILDER_COURSE_STATUS.paidout
                )
            )
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const onBackward = () => {
        navigate('/home')
    }

    const onPayFee = () => {
        mixpanel.track('Balance Viewed')
        navigate('/credit-builder/active/pay-fee')
    }

    const onCourseDetails = (course) => {
        mixpanel.track('Course Viewed - CB', {
            Course: course.product.name
        })

        navigate(`/credit-builder/courses/${course.product.id}`)
    }

    const disbursementMenu = [
        {
            icon: <Calendar2Icon />,
            title: 'Calendario de pago',
            action: () => {
                navigate('/credit-builder/active/payment-calendar')
            }
        },
        {
            icon: <ToDoIcon />,
            title: 'Historial de pagos',
            action: () => {
                navigate('/credit-builder/active/history-payment')
            }
        },
        {
            icon: <CurrencyDollarIcon />,
            title: 'Pagar cuota',
            action: onPayFee
        }
    ]

    return (
        <DisbursmentTemplate
            title="Credit Builder"
            menu={disbursementMenu}
            loan={loan}
            onBackward={onBackward}>
            <div>
                <div className="font-semibold mb-6">Crédito activo</div>

                <div className="rounded-md">
                    <img
                        src={order_active.product.image_url}
                        className="rounded-t-2xl"
                    />
                    <div className="text-lg font-semibold p-4 border border-slate-200-kiwi rounded-b-2xl border-t-0">
                        <div className="flex justify-between gap-4 mb-6">
                            <div className="">{order_active.product.name}</div>
                            <div className="text-blue-kiwi">
                                ${loan.totalLoanAmount}
                            </div>
                        </div>

                        <div className="flex text-sm justify-center gap-4">
                            <div
                                className="cursor-pointer rounded-3xl py-2 border border-regal-blue-kiwi text-regal-blue-kiwi flex items-center justify-center gap-2 w-full"
                                onClick={() => onCourseDetails(order_active)}>
                                <Eye2Icon className="w-5" /> Ver curso
                            </div>

                            {loan.balance !== 0 && (
                                <div
                                    className="cursor-pointer rounded-3xl py-2 text-white bg-regal-blue-kiwi flex items-center justify-center gap-2 w-full"
                                    onClick={onPayFee}>
                                    Pagar cuota
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {paidoutCourses.length > 0 && (
                <div>
                    <div className="font-semibold mb-6">
                        Créditos finalizados
                    </div>

                    <div className="flex flex-col gap-6">
                        {loading ? (
                            <Fragment>
                                {[...Array(3)].map((item, index) => (
                                    <PaidoutCourseSkeleton key={index} />
                                ))}
                            </Fragment>
                        ) : (
                            <Fragment>
                                {paidoutCourses.map((course) => (
                                    <PaidoutCourseComponent
                                        key={course.product.id}
                                        course={course}
                                        onCourseDetails={onCourseDetails}
                                    />
                                ))}
                            </Fragment>
                        )}
                    </div>
                </div>
            )}
        </DisbursmentTemplate>
    )
}
