import { PERSONAL_LOAN_STATUS } from 'constants'
import { UserContext } from 'context/UserContext'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import RequestLoan from './RequestLoan'
import ProgressLoan from './ProgressLoan'
import RejectedLoan from './RejectedLoan'

export default function HomeStatus({ className }) {
    const navigate = useNavigate()
    const { state: userState } = useContext(UserContext)
    const { user, products } = userState
    const { personal_loan } = products

    const RenderStatusCard = () => {
        const { tracking, status } = personal_loan
        const context = tracking?.context ?? {}

        const isApplyStatus =
            status === PERSONAL_LOAN_STATUS.apply &&
            !context.reviewing?.completed

        const isReviewingStatus =
            status === PERSONAL_LOAN_STATUS.apply &&
            context.reviewing?.completed

        const isRejectedStatus = status === PERSONAL_LOAN_STATUS.rejected
        const isApprovedtatus = status === PERSONAL_LOAN_STATUS.approved
        const isCurrentStatus = status === PERSONAL_LOAN_STATUS.current

        if (isApplyStatus) {
            return <RequestLoan />
        }

        if (isReviewingStatus || isApprovedtatus || isCurrentStatus) {
            return <ProgressLoan />
        }

        if (isRejectedStatus) {
            return <RejectedLoan />
        }
    }

    return (
        <div className={className}>
            <RenderStatusCard />
        </div>
    )
}
