import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import SentEmailOnboardingSVG from 'assets/onboarding_v3/sent_email.svg'
import Button from 'components/Button'

export default function ChangePhoneSentEmail() {
    const navigate = useNavigate()

    const onChangePhone = () => {
        navigate('/change-phone')
    }

    return (
        <Fragment>
            <div className="text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={SentEmailOnboardingSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    ¡Revisa tu correo electrónico!
                </h1>
                <p>
                    Enviamos un link de verificación para realizar el cambio de
                    número
                </p>
            </div>

            <Button
                onClick={onChangePhone}
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto">
                Enviar nuevamente
            </Button>
        </Fragment>
    )
}
