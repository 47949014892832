import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import ErrorOnboardingSVG from 'assets/onboarding_v3/phone_error.svg'
import Button from 'components/Button'

export default function ChangePhoneError() {
    const navigate = useNavigate()

    const onContinue = async () => {
        navigate('/login')
    }

    return (
        <Fragment>
            <div className="flex flex-col justify-center text-center m-auto mb-10">
                <img className="mx-auto mb-8" src={ErrorOnboardingSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    El número registrado ya existe
                </h1>
                <p>
                    Lamentablemente, el número que registraste ya se encuentra
                    en uso en nuestro sistema
                </p>
            </div>

            <Button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                onClick={onContinue}>
                Aceptar
            </Button>
        </Fragment>
    )
}
