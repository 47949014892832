import React, { Fragment } from 'react'
import InformationCircleSVG from 'assets/onboarding_v3/information_circle.svg'

export default function AddCreditCardRejected({ accept }) {
    return (
        <Fragment>
            <div className="flex flex-col justify-center min-h-full text-center m-auto">
                <img className="mx-auto mb-8" src={InformationCircleSVG} />
                <h1 className="text-dark-kiwi font-semibold text-2xl">
                    Error al agregar método de pagó, inténtalo nuevamente
                </h1>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={accept}>
                Continuar
            </button>
        </Fragment>
    )
}
