import phoneFormatter from 'phone-formatter'
import PatternFormat from 'react-number-format'
import Cookies from 'js-cookie'
import PhoneOnboardingSVG from 'assets/onboarding_v3/phone_change.svg'

import React, { useState, useEffect, useContext, useRef } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { AuthContext } from 'context/AuthContext'

import Button from 'components/Button'

export default function ChangePhoneNew() {
    const cellphoneInput = useRef()
    const navigate = useNavigate()

    const { checkChangePhone, sendCodeChangePhone, setState } =
        useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [cellphone, setCellphone] = useState('')
    const [error, setError] = useState('')

    const { tokenNewPhone } = useParams()

    useEffect(() => {
        checkPhoneToken()
    }, [])

    const checkPhoneToken = async () => {
        try {
            await checkChangePhone(tokenNewPhone)
            Cookies.set('tokenNewPhone', tokenNewPhone)
        } catch (error) {
            if (error.response.data.code !== 200) {
                return navigate('/login')
            }
        }
    }

    const onSendCellphoneCode = async (event) => {
        event.preventDefault()

        if (loading) return

        if (cellphone.length !== 10) {
            return setError('Ingresa un número de celular válido')
        }

        try {
            setLoading(true)

            const formattedCellphone = phoneFormatter.format(
                cellphone,
                '+1(NNN)NNN-NNNN'
            )

            const { data } = await sendCodeChangePhone({
                cellphone: formattedCellphone,
                service: ''
            })

            if (data.success) {
                setState((prev) => ({
                    ...prev,
                    new_cellphone: formattedCellphone
                }))

                navigate('/change-phone/verify')
            }
        } catch (error) {
            if (error.response.data.messages === 'Phone already exist') {
                return navigate('/change-phone/error')
            }

            setError(
                'Parece que hubo un problema. Inténtalo de nuevo por favor'
            )
        } finally {
            setLoading(false)
        }
    }

    const onSetCellphone = ({ value }) => {
        if (value.length === 10) {
            setCellphone(value)
            cellphoneInput.current.blur()
        } else {
            setCellphone('')
        }

        setError('')
    }

    return (
        <form onSubmit={onSendCellphoneCode} className="flex flex-col grow">
            <img className="mx-auto mt-24 mb-8" src={PhoneOnboardingSVG} />

            <div className="mb-10">
                <h1 className="text-dark-kiwi text-center font-semibold mb-4 text-2xl">
                    Ingresa tu nuevo número de celular
                </h1>

                <p className="mb-10 text-center">
                    Te enviaremos un código de 6 dígitos para verificar tu
                    cuenta
                </p>

                <div className="relative">
                    <div
                        className={`absolute top-1/2 left-4 -translate-y-1/2 ${
                            error ? 'text-red-kiwi' : ''
                        }`}>
                        +1
                    </div>
                    <PatternFormat
                        className={`w-full rounded-lg py-2 px-4 pl-10 ${
                            error
                                ? 'border-red-kiwi focus:border-red-kiwi bg-red-kiwi/5 placeholder:text-red-kiwi/50'
                                : 'border-gray-200-kiwi focus:border-blue-kiwi bg-gray-100-kiwi placeholder:text-gray-400-kiwi'
                        }`}
                        format="(###) ### ####"
                        placeholder="(000) 000 0000"
                        mask=""
                        getInputRef={cellphoneInput}
                        onValueChange={onSetCellphone}
                    />
                </div>

                {error !== '' && (
                    <small className="text-red-kiwi inline-block text-xs w-full mt-2">
                        {error}
                    </small>
                )}
            </div>

            <Button
                id="send_cellphone_code-gtm"
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-auto"
                loading={loading}
                type="submit">
                Cambiar número
            </Button>
        </form>
    )
}
