import React, { useContext, Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'context/UserContext'

import { Calendar2Icon, ChevronLeftIcon } from 'assets/icons_v3/fonts'
import { LoanAPI } from 'api'
import LoadingModal from 'components/Modals/Loading'
import formatDate from 'utils/formatDate'
import BackwardButton from 'components/Button/Backward'

export default function ActiveLoanPaymentCalendar() {
    const navigate = useNavigate()
    const [schedulesPayments, setSchedulesPayments] = useState([])
    const [showLoader, setShowLoader] = useState(true)
    const { state: userState } = useContext(UserContext)
    const {
        products: { credit_builder }
    } = userState

    useEffect(() => {
        onGetSchedulesPayments()
    }, [])

    const onGetSchedulesPayments = async () => {
        const { data } = await LoanAPI.getScheduledPayments({
            loanpro_tmp_loan_id: credit_builder.loan.id
        })

        setShowLoader(false)
        setSchedulesPayments(data.data)
    }

    const onBackward = () => {
        navigate('/credit-builder/active')
    }

    const onPayLoan = () => {
        navigate('/credit-builder/active/pay-fee')
    }

    const transformDate = (date) => {
        const [month, day, year] = date.split('/')
        return formatDate(year + '/' + day + '/' + month, 'MMMM DD, YYYY')
    }

    return (
        <Fragment>
            <LoadingModal isVisible={showLoader} />
            <BackwardButton onBackward={onBackward} />

            <div className="mb-auto">
                <h1 className="text-dark-kiwi font-semibold text-2xl mb-10">
                    Calendario de pagos
                </h1>
                <div className="flex flex-col gap-6">
                    {schedulesPayments.map((payment) => (
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-4">
                                <div className="bg-slate-100-kiwi rounded-full h-10 w-10 flex">
                                    <Calendar2Icon className="m-auto text-regal-blue-kiwi" />
                                </div>
                                <div className="capitalize">
                                    {transformDate(payment.date)}
                                </div>
                            </div>
                            <div className="font-semibold text-slate-900-kiwi">
                                ${Number(payment.chargeAmount).toFixed(2)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <button
                className="bg-blue-kiwi text-white rounded-xl p-3 mt-10"
                onClick={onPayLoan}>
                Pagar ya
            </button>
        </Fragment>
    )
}
